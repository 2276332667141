import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import type { ApiEmployeeGroup } from '@pib/api';
import { useAuth } from '@pib/auth';
import { useConfig } from 'core/modules/config';
import { Icon } from 'modules/ui';
import { toKebabCase } from 'utils';

import { version } from '../../../package.json';
import { SidePanel, SidePanelContent } from '../Sidepanel';
import LanguageSelect from './LanguageSelect';

interface Props {
  groups?: ApiEmployeeGroup[];
}

const MobileNav = ({ groups }: Props) => {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const { config } = useConfig();

  const [isOpen, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!isOpen);
  };

  return (
    <>
      <Icon
        type="bars"
        className={clsx(
          'transition-transform duration-300',
          isOpen && 'rotate-90',
        )}
        onClick={toggleOpen}
      />
      <SidePanel
        visible={isOpen}
        width={250}
        withOverlay
        onClickOutside={toggleOpen}
      >
        <SidePanelContent>
          <Icon
            type="times"
            color="primary"
            fontSize={2.5}
            onClick={toggleOpen}
          />
          <div className="flex flex-col mt-4">
            {config?.company.isDashBoardEnabled && (
              <Link
                onClick={toggleOpen}
                className="flex items-center uppercase cursor-pointer text-neutral-700 no-underline py-3 mt-4 hover:text-primary-500"
                to="/dashboard"
              >
                <Icon type="tachometer-alt" />
                <div>{t('PORTAL.NAV.DASHBOARD')}</div>
              </Link>
            )}
            {groups?.map(group => {
              const kebabName = toKebabCase(group.name);
              return (
                <Link
                  className="flex items-center uppercase cursor-pointer text-neutral-700 no-underline py-3 hover:text-primary-500"
                  key={kebabName}
                  to={`/info/${kebabName}`}
                  onClick={toggleOpen}
                >
                  {group.icon && group.icon.name && (
                    <Icon
                      type={group.icon.name}
                      color={group.icon.color}
                      marginRight
                    />
                  )}
                  <div>{group.name}</div>
                </Link>
              );
            })}

            {config?.company.isFlexplanEnabled && (
              <Link
                to="/flexplan"
                onClick={toggleOpen}
                className="flex items-center uppercase cursor-pointer text-neutral-700 no-underline py-3 mt-4 hover:text-primary-500"
              >
                <Icon type="coins" marginRight />
                <div>{t('PORTAL.NAV.FLEXPLAN')}</div>
              </Link>
            )}

            {config?.company.isMeetingsEnabled && (
              <Link
                to="/afspraken"
                onClick={toggleOpen}
                className="flex items-center uppercase cursor-pointer text-neutral-700 no-underline py-3 mt-4 hover:text-primary-500"
              >
                <Icon type="calendar" marginRight />
                <div>{t('PORTAL.NAV.MEETINGS')}</div>
              </Link>
            )}

            <Link
              to="/login"
              onClick={() => {
                logout();
                toggleOpen();
              }}
              className="flex items-center uppercase cursor-pointer text-neutral-700 no-underline py-3 mt-4 hover:text-primary-500"
            >
              <Icon type="sign-out-alt" marginRight />
              <div>logout</div>
            </Link>
            <div className="flex justify-center my-4">
              <LanguageSelect />
            </div>
            <div className="text-sm mb-1 mr-1 mt-auto text-right text-neutral-400">
              Versie: {version}
            </div>
          </div>
        </SidePanelContent>
      </SidePanel>
    </>
  );
};

export default MobileNav;
