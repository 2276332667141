import { Wrapper } from '../../../modules/ui';
import Spinner from '../../Spinner';

const LoadingLayout = () => (
  <Wrapper>
    <Spinner className="mx-auto" />
  </Wrapper>
);

export default LoadingLayout;
