import type { GetEmployeeValues } from '@pib/api';

class EmployeeQueryKeys {
  private prefix = 'employee';

  getEmployee(ids: GetEmployeeValues) {
    return [this.prefix, ids];
  }
}

export default EmployeeQueryKeys;
