const easeInOutQuad = (
  time: number,
  start: number,
  change: number,
  duration: number,
) => {
  time /= duration / 2;
  if (time < 1) return (change / 2) * time * time + start;
  time--; // eslint-disable-line
  return (-change / 2) * (time * (time - 2) - 1) + start;
};

export const scrollTo = (to: number, duration: number) => {
  const start = document.documentElement.scrollTop;
  const change = to - start;
  let currentTime = 0;
  const increment = 30;

  const animateScroll = function () {
    currentTime += increment;
    const val = easeInOutQuad(currentTime, start, change, duration);
    if (document.body.scrollTop) {
      document.body.scrollTop = val;
    } else {
      document.documentElement.scrollTop = val;
    }
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
};
