import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const defaultEmail = 'info@partnerinbenefits.be';

const ModalTitle = styled.h2`
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  margin-bottom: 10px;
  background-color: ${p => p.theme.secondary};
  color: #fff;
`;

const LoginLink = styled.button.attrs({ type: 'button' })`
  border-color: ${p => p.theme.border};
  outline: 0;
  color: #fff;
  background-color: ${p => p.theme.primary};
  padding: 8px 16px;
  border-radius: 4px;
  &:hover {
    text-decoration: none;
  }
`;

const ModalBody = styled.p`
  margin-bottom: 20px;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ListOfReasons = styled.ul`
  list-style: disc;
  margin-left: 20px;
  margin-top: 10px;
`;

const ModalWrapper = styled.div`
  padding: 20px;
`;

interface Props {
  email?: string;
  userEmail?: string;
  handleClose: () => void;
}

const UnknownUserModal = ({ email, handleClose, userEmail }: Props) => {
  const { t } = useTranslation();
  const mailToEmail = email || defaultEmail;

  const handleContactHR = () => {
    window.location.href = `mailto:${mailToEmail}`;
    handleClose();
  };

  const translationComponent = (
    <>
      <ModalTitle>{t('LOGIN.ERROR.TITLE')}</ModalTitle>
      <ModalWrapper>
        <ModalBody>
          <p>
            {userEmail
              ? t('LOGIN.ERROR.LOGGED_IN_WITH_EMAIL', {
                  mail: userEmail || '',
                })
              : t('LOGIN.ERROR.LOGGED_IN')}
          </p>
          <p>
            {userEmail
              ? t('LOGIN.ERROR.EMAIL_NOT_RECOGNIZED')
              : t('LOGIN.ERROR.NOT_RECOGNIZED')}
          </p>

          <ListOfReasons>
            <li>{t('LOGIN.ERROR.NEW_EMPLOYEE')}</li>
            <li>{t('LOGIN.ERROR.EX_EMPLOYEE')}</li>
            {userEmail && (
              <li>
                {t('LOGIN.ERROR.DIFFERENT_EMAIL_KNOWN', { mail: userEmail })}
              </li>
            )}
          </ListOfReasons>
        </ModalBody>
        <ModalButtonContainer>
          <LoginLink onClick={handleContactHR}>
            {t('LOGIN.ERROR.CONTACT_HR_BUTTON')}
          </LoginLink>
        </ModalButtonContainer>
      </ModalWrapper>
    </>
  );

  return translationComponent;
};

export default UnknownUserModal;
