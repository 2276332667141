class MeetingSlotApi {
    constructor(fetchService) {
        this.fetchService = fetchService;
    }
    getMeetingSlots({ companyId, employeeId }) {
        return this.fetchService.get(`/companies/${companyId}/employees/${employeeId}/slots`);
    }
    deleteMeetingSlot({ companyId, locationId, meetingId, slotId, }) {
        return this.fetchService.delete(`/companies/${companyId}/locations/${locationId}/meetings/${meetingId}/slots/${slotId}`);
    }
    planMeetingSlot({ companyId, locationId, meetingId, slotId, employeeId, note, }) {
        return this.fetchService.post(`/companies/${companyId}/locations/${locationId}/meetings/${meetingId}/slots/${slotId}/plan`, { note, employeeId });
    }
}
export default MeetingSlotApi;
