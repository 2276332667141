import { getI18n } from 'react-i18next';

import {
  BlobApi,
  CompanyLocationApi,
  ConfigApi,
  ContactApi,
  DashboardApi,
  EmployeeApi,
  FetchService,
  FlexplanApi,
  MeetingApi,
  MeetingSlotApi,
  UserApi,
  WizardApi,
} from '@pib/api';

export const fetchService = new FetchService({
  getLanguage: () => getI18n().language,
});

export const configApi = new ConfigApi(fetchService);
export const userApi = new UserApi(fetchService);
export const contactApi = new ContactApi(fetchService);
export const flexplanApi = new FlexplanApi(fetchService);
export const meetingApi = new MeetingApi(fetchService);
export const meetingSlotApi = new MeetingSlotApi(fetchService);
export const companyLocationApi = new CompanyLocationApi(fetchService);
export const employeeApi = new EmployeeApi(fetchService);
export const blobApi = new BlobApi(fetchService);
export const wizardApi = new WizardApi(fetchService);
export const dashboardApi = new DashboardApi(fetchService);
