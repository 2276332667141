import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export type ModalTypes = 'unknownUser';

interface UserUnknownModalPayload {
  email: string;
  userEmail: string;
}

type ModalPayload = UserUnknownModalPayload;

interface ModalStore {
  activeModal: {
    type: ModalTypes;
    payload: ModalPayload;
  } | null;
  actions: {
    openModal: (type: ModalTypes, payload: ModalPayload) => void;
    closeModal: () => void;
  };
}

const useModalStore = create<ModalStore, [['zustand/devtools', ModalStore]]>(
  devtools(set => ({
    activeModal: null,
    actions: {
      openModal: (type: ModalTypes, payload: ModalPayload) =>
        set({ activeModal: { type, payload } }),
      closeModal: () => set({ activeModal: null }),
    },
  })),
);

export const useActiveModal = () => useModalStore(state => state.activeModal);
export const useModalActions = () => useModalStore(state => state.actions);
