import { useQuery } from 'react-query';

import type { ApiEmployee, ApiError, GetEmployeeValues } from '@pib/api';
import { employeeApi } from 'core/di';

import EmployeeQueryKeys from './EmployeeQueryKeys';

const employeeQueryKeys = new EmployeeQueryKeys();

const STALE_30_MINUTES = 1800000;

export const useEmployee = (ids: Partial<GetEmployeeValues>) => {
  const { data, isLoading, error } = useQuery<ApiEmployee, ApiError>(
    employeeQueryKeys.getEmployee(ids as GetEmployeeValues),
    () => employeeApi.getEmployee(ids as GetEmployeeValues),
    {
      staleTime: STALE_30_MINUTES,
      enabled: Boolean(ids.employeeId),
    },
  );

  return {
    employee: data,
    isLoading,
    error,
  };
};
