import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '@pib/auth';
import { Breakpoints } from 'common';
import { useConfig } from 'core/modules/config';
import { useEmployee } from 'core/modules/employee/hooks';
import { useCurrentUser } from 'core/modules/user';
import { Text, Wrapper } from 'modules/ui';
import { toKebabCase } from 'utils';

import { version } from '../../../package.json';
import {
  BookmarkIcon,
  ItemIcon,
  ItemLink,
  ItemText,
  SideBar,
  UserName,
  WrapperContainer,
} from './styles';

interface Props {
  children: ReactNode;
  activeTab: 'flexplan' | 'meetings' | 'info' | 'contact' | 'dashboard';
}

const TabWrapper = ({ activeTab = 'info', children }: Props) => {
  const { t } = useTranslation();

  const { logout, isLoading: isLoadingLogout } = useAuth();
  const { config } = useConfig();
  const { currentUser } = useCurrentUser();
  const { employee } = useEmployee({
    companyId: currentUser.companyId,
    employeeId: currentUser.id,
  });

  const isFlexplanEnabled = config?.company.isFlexplanEnabled ?? false;
  const isMeetingsEnabled = config?.company.isMeetingsEnabled ?? false;
  const isDashBoardEnabled = config?.company.isDashBoardEnabled ?? false;
  const userName = currentUser.name;

  return (
    <Breakpoints>
      {({ isBiggerThenTablet }) => (
        <Wrapper>
          <WrapperContainer>
            {isBiggerThenTablet && (
              <SideBar>
                <>
                  <BookmarkIcon type="bookmark" />{' '}
                  <UserName alignCenter>
                    <div className="flex flex-col">
                      <Text fontWeight={700}>{userName}</Text>
                    </div>
                  </UserName>
                  <div className="flex flex-col">
                    {isDashBoardEnabled && (
                      <ItemLink
                        to="/dashboard"
                        variant={{
                          active: activeTab === 'dashboard',
                        }}
                      >
                        <ItemIcon type="info" />
                        <ItemText>{t('PORTAL.NAV.DASHBOARD')}</ItemText>
                      </ItemLink>
                    )}
                    {employee?.groups.map(group => {
                      const kebabName = toKebabCase(group.name);
                      return (
                        <ItemLink
                          key={kebabName}
                          to={`/info/${kebabName}`}
                          variant={{
                            active: activeTab === `info/${kebabName}`,
                          }}
                        >
                          {group.icon && group.icon.name && (
                            <ItemIcon
                              type={group.icon.name}
                              color={group.icon.color ?? ''}
                            />
                          )}
                          <ItemText>{group.name}</ItemText>
                        </ItemLink>
                      );
                    })}
                    {isFlexplanEnabled && (
                      <ItemLink
                        to="/flexplan"
                        variant={{
                          active: activeTab === 'flexplan',
                        }}
                      >
                        <ItemIcon type="coins" />
                        <ItemText>{t('PORTAL.NAV.FLEXPLAN')}</ItemText>
                      </ItemLink>
                    )}
                    {isMeetingsEnabled && (
                      <ItemLink
                        to="/afspraken"
                        variant={{
                          active: activeTab === 'meetings',
                        }}
                      >
                        <ItemIcon type="calendar" />
                        <ItemText>{t('PORTAL.NAV.MEETINGS')}</ItemText>
                      </ItemLink>
                    )}
                    <ItemLink
                      to="/contact"
                      variant={{
                        active: activeTab === 'contact',
                      }}
                    >
                      <ItemIcon type="envelope" />
                      <ItemText>{t('PORTAL.NAV.CONTACT')}</ItemText>
                    </ItemLink>
                    <ItemLink
                      as="button"
                      variant={{ active: false, last: true }}
                      onClick={logout}
                    >
                      <ItemIcon
                        type={isLoadingLogout ? 'circle-notch' : 'sign-out-alt'}
                        spin={isLoadingLogout}
                      />
                      <ItemText>{t('PORTAL.NAV.LOGOUT')}</ItemText>
                    </ItemLink>
                  </div>
                </>

                <div className="text-sm mb-1 mr-1 mt-auto text-right text-neutral-400">
                  {version}
                </div>
              </SideBar>
            )}
            {children}
          </WrapperContainer>
        </Wrapper>
      )}
    </Breakpoints>
  );
};

export default TabWrapper;
