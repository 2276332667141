import { Link as RouteLink } from 'react-router-dom';
import styled from 'styled-components';
// import { Button as AntButton } from 'antd';

export const Content = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${p => p.theme.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.h1`
  color: ${p => p.theme.white};
  font-size: 20rem;
  margin: 0;
  line-height: 1;
`;

export const SubTitle = styled.h2`
  color: ${p => p.theme.white};
`;

export const Link = styled(RouteLink)`
  color: ${p => p.theme.primary};
  background-color: ${p => p.theme.white};
  padding: 8px 16px;
  border-radius: 4px;
  margin: 16px 0;
`;

export const getIllustration = (component: any) => styled(component)`
  max-width: 800px;
  width: 100%;
`;
