export const throttle = (func: (...args: any) => void, limit: number) => {
  let inThrottle: boolean;
  return function t(...args: any) {
    // eslint-disable-next-line
    // @ts-ignore
    const context = this; // eslint-disable-line
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};

export const buildQueryString = <T extends Record<string, any>>(query?: T) => {
  if (query) {
    const qs = Object.entries(query)
      .filter(pair => pair[1] !== undefined)
      .map(pair =>
        pair
          .filter(i => i !== null)
          .map(encodeURIComponent)
          .join('='),
      )
      .join('&');

    return qs && `?${qs}`;
  }
  return '';
};
