import type { ComponentType } from 'react';
import { Redirect } from 'react-router-dom';

import { useAuth } from '@pib/auth';
import { useConfig } from 'core/modules/config';

import { LoadingLayout } from '../Loading';

function withAuthRedirect<P = Record<string, unknown>>({
  WrappedComponent,
  LoadingComponent,
  expectedAuth,
  location,
}: {
  WrappedComponent: ComponentType<P>;
  LoadingComponent: ComponentType;
  expectedAuth: boolean;
  location: string;
}) {
  const WithAuthRedirectWrapper = (props: P) => {
    const { isLoggedIn, isLoading: isLoadingAuth } = useAuth();
    const { config, isLoading: isLoadingConfig } = useConfig();
    const isFlexplanEnabled = config?.company.isFlexplanEnabled;
    const isDashboardEnabled = config?.company.isDashBoardEnabled;
    if (isLoadingAuth || isLoadingConfig) {
      return <LoadingComponent />;
    }
    if (expectedAuth !== isLoggedIn) {
      if (isFlexplanEnabled && expectedAuth === false) {
        return <Redirect to="/flexplan" />;
      }
      if (isDashboardEnabled && expectedAuth === false) {
        return <Redirect to="/dashboard" />;
      }
      return <Redirect to={location} />;
    }
    return <WrappedComponent {...props} />;
  };
  return WithAuthRedirectWrapper;
}

export function withAuth<P>(
  WrappedComponent: ComponentType<P>,
  location = '/login',
) {
  return withAuthRedirect({
    LoadingComponent: LoadingLayout,
    WrappedComponent,
    location,
    expectedAuth: true,
  });
}
