import { LoginCard } from 'pages/login/components';

import Spinner from '../../Spinner';

const LoadingLogin = () => (
  <LoginCard error={null}>
    <Spinner className="mx-auto" />
  </LoginCard>
);

export default LoadingLogin;
