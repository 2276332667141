import { Router } from 'react-router-dom';
import { ModalContainer } from 'shared/modal/ModalContainer';

import { ErrorBoundary, Spinner } from 'common';

import AppInitializer from './AppInitializer';
import AppProviders from './AppProviders';
import history from './history';
import I18nWrapper from './I18nWrapper';
import Routes from './routes';

const App = () => (
  <ErrorBoundary>
    <Router history={history}>
      <I18nWrapper>
        <AppProviders>
          <AppInitializer placeholder={<Spinner />}>
            <Routes />
            <ModalContainer />
          </AppInitializer>
        </AppProviders>
      </I18nWrapper>
    </Router>
  </ErrorBoundary>
);

export default App;
