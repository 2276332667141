import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { MyBenPlus } from 'assets/svg';
import type { Location } from 'history';

import { useAuth } from '@pib/auth';
import { Breakpoints } from 'common';
import { useEmployee } from 'core/modules/employee';
import { Icon } from 'modules/ui';

import LanguageSelect from './LanguageSelect';
import MobileNav from './MobileNav';

const isWizardRoute = (location: Location) =>
  location.pathname.includes('/wizard');

const HeaderCmp = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { isLoggedIn, currentUser } = useAuth();
  const { employee } = useEmployee({
    companyId: currentUser?.companyId,
    employeeId: currentUser?.id,
  });

  return (
    <header className="w-full bg-white py-5">
      <div className="container">
        <div className="flex justify-between items-center">
          <MyBenPlus className="w-1/4" />
          {!isWizardRoute(location) && (
            <Breakpoints>
              {({ isSmallerThenTablet, isBiggerThenTablet }) => {
                const loginPages = location.pathname.includes('login');
                if (isSmallerThenTablet && loginPages) {
                  return <MobileNav groups={employee?.groups} />;
                }
                if (isBiggerThenTablet && isLoggedIn) {
                  return (
                    <div className="flex flex-col items-center justify-between">
                      <Link to="/contact">
                        <div className="flex items-center p-3 bg-neutral-200 rounded mb-2">
                          <span className="text-secondary-500 mr-3">
                            {t('HEADER.QUESTION')}
                          </span>
                          {t('COMMON.CONTACT_US')}
                          <Icon type="chevron-right" className="ml-1" />
                        </div>
                      </Link>
                      <LanguageSelect />
                    </div>
                  );
                }
                return <LanguageSelect />;
              }}
            </Breakpoints>
          )}
        </div>
      </div>
    </header>
  );
};

export default HeaderCmp;
