import { PureComponent } from 'react';
import type { DayModifiers } from 'react-day-picker';
import Picker, { DateUtils, LocaleUtils } from 'react-day-picker';
import { withTranslation } from 'react-i18next';
import type { i18n as i18next } from 'i18next';
import styled from 'styled-components';

import { theme } from 'modules/ui';

import 'react-day-picker/lib/style.css';

const DayPicker = styled(Picker)`
  &.DayPicker {
    display: flex;
    justify-content: center;
  }
  .DayPicker-Day {
    outline: none;
  }
  .DayPicker-Caption > div {
    font-weight: 600;
  }
  .DayPicker-NavButton {
    width: 20px;
    height: 20px;
  }
`;
const WEEKDAYS_LONG: Record<string, string[]> = {
  en: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  nl: [
    'Zondag',
    'Maandag',
    'Dinsdag',
    'Woensdag',
    'Donderdag',
    'Vrijdag',
    'Zaterdag',
  ],
  fr: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
};
const WEEKDAYS_SHORT: Record<string, string[]> = {
  en: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  nl: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
  fr: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
};
const MONTHS: Record<string, string[]> = {
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  nl: [
    'Januari',
    'Februari',
    'Maart',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Augustus',
    'September',
    'Oktober',
    'November',
    'December',
  ],
  fr: [
    'Janvier',
    'Février',
    'Mars -',
    'Avril',
    'Mai -',
    'Juin -',
    'Juillet',
    'Aout -',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ],
};

function formatDay(d: Date, locale: string) {
  return `${WEEKDAYS_LONG[locale][d.getDay()]}, ${d.getDate()} ${
    MONTHS[locale][d.getMonth()]
  } ${d.getFullYear()}`;
}

function formatMonthTitle(d: Date, locale: string) {
  return `${MONTHS[locale][d.getMonth()]} ${d.getFullYear()}`;
}

function formatWeekdayShort(i: number, locale: string) {
  return WEEKDAYS_SHORT[locale][i];
}

function formatWeekdayLong(i: number, locale: string) {
  return WEEKDAYS_SHORT[locale][i];
}

const localeUtils = {
  ...LocaleUtils,
  formatDay,
  formatMonthTitle,
  formatWeekdayShort,
  formatWeekdayLong,
};

interface Props {
  selectedDate?: Date;
  highlightDates: Date[];
  onSelectDay: (day?: Date) => void;
  i18n: i18next;
}

class DatePicker extends PureComponent<Props> {
  static defaultProps = {
    highlightDates: [],
  };

  handleDayClick = (day: Date, { selected, disabled }: DayModifiers) => {
    if (disabled) {
      return;
    }
    const { onSelectDay } = this.props;
    if (selected) {
      onSelectDay(undefined);
      return;
    }
    onSelectDay(day);
  };

  getModifiers = () => {
    const { highlightDates } = this.props;
    const modifiers = {
      highlight: highlightDates,
    };
    const styles = {
      highlight: {
        color: theme.white,
        backgroundColor: theme.confirm,
      },
    };
    return { modifiers, styles };
  };

  onDisabledDays = (day: Date) => {
    const { highlightDates } = this.props;
    return !highlightDates.find(date => DateUtils.isSameDay(day, date));
  };

  render() {
    const { selectedDate, i18n } = this.props;
    const { modifiers, styles } = this.getModifiers();
    return (
      <DayPicker
        disabledDays={this.onDisabledDays}
        locale={i18n.language.split('-')[0]}
        localeUtils={localeUtils}
        onDayClick={this.handleDayClick}
        selectedDays={selectedDate}
        modifiers={modifiers}
        modifiersStyles={styles}
        firstDayOfWeek={1}
        showOutsideDays
      />
    );
  }
}

export default withTranslation()(DatePicker);
