import type { ReactNode } from 'react';
import { createContext, useEffect, useState } from 'react';

import { useAuth } from '@pib/auth';

export const AppInitializedContext = createContext(false);

interface Props {
  children: ReactNode;
  placeholder: ReactNode;
}

const AppInitializer = ({ children, placeholder }: Props) => {
  const [initialized, setInitialized] = useState(false);
  const { initialize: initializeAuth } = useAuth();

  useEffect(() => {
    if (!initialized) {
      Promise.all([initializeAuth()]).then(() => {
        setInitialized(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{initialized ? children : placeholder}</>;
};

export default AppInitializer;
