import type { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MyBenPlus } from 'assets/svg';
import { useModalActions } from 'shared/modal/modal.store';
import styled from 'styled-components';

import type { ApiError } from '@pib/api';
import { Container, Wrapper } from 'modules/ui';

import { version } from '../../../../package.json';

const BoxWrapper = styled(Wrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginContainer = styled(Container)`
  display: flex;
  justify-content: center;
  padding: 20px 0;
`;

const LoginSquare = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 400px;
  padding: 40px 20px 8px;
  border-radius: 5px;
  background: ${p => p.theme.white};
  box-shadow: 0px 0px 10px 2px #f1f1f1;
`;

const Logo = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
`;

interface Props {
  logoUrl?: string;
  error: Nullable<ApiError>;
  children: ReactNode;
}

const LoginCard = ({ logoUrl, error, children }: Props) => {
  const { openModal } = useModalActions();

  // when the error.status is 404, open the modal, only check this once
  if (error?.status === 404) {
    openModal('unknownUser', {
      email: error.extraProperties?.contactEmail,
      userEmail: error.extraProperties?.userEmail,
    });
  }

  return (
    <BoxWrapper>
      <LoginContainer>
        <LoginSquare>
          {logoUrl ? (
            <Logo src={logoUrl} alt="logo" />
          ) : (
            <MyBenPlus className="w-full m-auto" />
          )}
          {error && error?.status !== 404 && (
            <div>
              <p className="text-xl text-danger-600 mt-4">
                <FontAwesomeIcon
                  icon={['fas', 'exclamation-triangle']}
                  className="mr-2"
                />
                Er is iets mis gegaan
              </p>
              {error?.status < 500 && error?.message && (
                <p className="text-sm text-danger-600 mt-2">{error.message}</p>
              )}
            </div>
          )}
          {children}
          <span className="text-sm mt-2 ml-auto text-neutral-400">
            {version}
          </span>
        </LoginSquare>
      </LoginContainer>
    </BoxWrapper>
  );
};

export default LoginCard;
