import styles from './savings.module.css';

export default ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/"
    viewBox="-300 0 1000 652.81944"
    className={className}
  >
    <defs>
      <linearGradient
        id="b604d933-963c-47a0-8899-51e94ba39cbc"
        x1="882.01746"
        y1="830.63698"
        x2="882.01746"
        y2="326.59687"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="gray" stopOpacity="0.25" />
        <stop offset="0.53514" stopColor="gray" stopOpacity="0.12" />
        <stop offset="1" stopColor="gray" stopOpacity="0.1" />
      </linearGradient>
    </defs>
    <title>Savings</title>
    <path
      className={styles.cls1}
      d="M629.05,694.12c1.47,6.18,6.77,9.9,11.88,12.09,7.86,3.36,16.22,4.62,24.52,5.77,24,3.32,48.16,5.95,72.34,5.43,29.35-.64,58.53-5.9,87.87-6.16,9.78-.09,19.87.32,28.94-4s16.92-14.78,15.77-26.23c-1-10.48-8.91-18-16.71-23.19-6.86-4.54-14.21-8.17-21.94-9.87-9.2-2-18.64-1.28-28-.84-33.21,1.54-66.6-1-99.62,3.55a242,242,0,0,0-50.3,12.51c-5.89,2.15-13.89,3.66-18,9.47C632,678,627.37,687,629.05,694.12Z"
      transform="translate(-300.9 -99.06)"
    />
    <ellipse
      className={styles.cls2}
      cx="394.37"
      cy="521.49"
      rx="135.22"
      ry="31.94"
    />
    <path
      className={styles.cls3}
      d="M560.05,646.24c0,17.64,60.54,31.94,135.22,31.94s135.22-14.3,135.22-31.94V620.55c0,17.64-60.54,31.94-135.22,31.94s-135.22-14.3-135.22-31.94Z"
      transform="translate(-300.9 -99.06)"
    />
    <polygon
      className={styles.cls4}
      points="525.54 529.65 525.1 550.72 524.66 529.65 525.54 529.65"
    />
    <polygon
      className={styles.cls4}
      points="514.49 536.32 514.04 558.67 513.61 536.32 514.49 536.32"
    />
    <polygon
      className={styles.cls4}
      points="500.09 542.16 499.65 563.67 499.21 542.16 500.09 542.16"
    />
    <polygon
      className={styles.cls4}
      points="482.58 545.86 482.33 546.47 482.35 546.34 481.92 569.28 481.49 546.34 481.49 546.26 481.51 546.2 481.74 545.59 482.58 545.86"
    />
    <polygon
      className={styles.cls4}
      points="462.75 549.05 462.31 574.95 461.87 549.05 462.75 549.05"
    />
    <polygon
      className={styles.cls4}
      points="442.31 551.34 441.87 577.21 441.43 551.34 442.31 551.34"
    />
    <polygon
      className={styles.cls4}
      points="419.78 553.01 419.34 578.68 418.9 553.01 419.78 553.01"
    />
    <polygon
      className={styles.cls4}
      points="394.54 553.64 394.1 579.29 393.66 553.64 394.54 553.64"
    />
    <polygon
      className={styles.cls4}
      points="370.98 553.14 370.51 554.59 370.52 554.47 370.11 577.22 369.7 554.47 369.7 554.42 369.72 554.36 370.13 552.9 370.98 553.14"
    />
    <polygon
      className={styles.cls4}
      points="351.78 551.97 351.34 575.59 350.9 551.97 351.78 551.97"
    />
    <polygon
      className={styles.cls4}
      points="334.05 550.09 333.61 574.57 333.17 550.09 334.05 550.09"
    />
    <polygon
      className={styles.cls4}
      points="314.42 547.04 314.2 548.29 314.21 548.21 313.79 571.38 313.37 548.21 313.37 548.19 313.38 548.14 313.55 546.88 314.42 547.04"
    />
    <polygon
      className={styles.cls4}
      points="296.08 543.63 295.64 568.25 295.2 543.63 296.08 543.63"
    />
    <polygon
      className={styles.cls4}
      points="280.62 539.04 280.38 539.44 280.43 539.24 280 560.78 279.57 539.24 279.56 539.14 279.61 539.05 279.84 538.64 280.62 539.04"
    />
    <polygon
      className={styles.cls4}
      points="267.5 532.99 267.06 551.97 266.63 532.99 267.5 532.99"
    />
    <ellipse
      className={styles.cls2}
      cx="382.54"
      cy="507.09"
      rx="135.22"
      ry="31.94"
    />
    <path
      className={styles.cls3}
      d="M548.22,631.84c0,17.64,60.54,31.94,135.22,31.94s135.22-14.3,135.22-31.94V606.15c0,17.64-60.54,31.94-135.22,31.94s-135.22-14.3-135.22-31.94Z"
      transform="translate(-300.9 -99.06)"
    />
    <polygon
      className={styles.cls4}
      points="513.71 515.25 513.27 536.31 512.83 515.25 513.71 515.25"
    />
    <polygon
      className={styles.cls4}
      points="502.66 521.92 502.22 544.26 501.78 521.92 502.66 521.92"
    />
    <polygon
      className={styles.cls4}
      points="488.26 527.76 487.82 549.26 487.38 527.76 488.26 527.76"
    />
    <polygon
      className={styles.cls4}
      points="470.75 531.45 470.5 532.06 470.52 531.93 470.09 554.88 469.67 531.93 469.66 531.86 469.69 531.8 469.91 531.18 470.75 531.45"
    />
    <polygon
      className={styles.cls4}
      points="450.92 534.64 450.48 560.54 450.05 534.64 450.92 534.64"
    />
    <polygon
      className={styles.cls4}
      points="430.48 536.94 430.04 562.81 429.6 536.94 430.48 536.94"
    />
    <polygon
      className={styles.cls4}
      points="407.95 538.61 407.51 564.27 407.07 538.61 407.95 538.61"
    />
    <polygon
      className={styles.cls4}
      points="382.71 539.23 382.27 564.89 381.83 539.23 382.71 539.23"
    />
    <polygon
      className={styles.cls4}
      points="359.15 538.74 358.68 540.18 358.7 540.07 358.28 562.81 357.87 540.07 357.87 540.01 357.89 539.95 358.3 538.49 359.15 538.74"
    />
    <polygon
      className={styles.cls4}
      points="339.95 537.57 339.51 561.18 339.07 537.57 339.95 537.57"
    />
    <polygon
      className={styles.cls4}
      points="322.22 535.69 321.78 560.16 321.34 535.69 322.22 535.69"
    />
    <polygon
      className={styles.cls4}
      points="302.59 532.63 302.37 533.88 302.38 533.81 301.96 556.97 301.55 533.81 301.55 533.79 301.55 533.74 301.72 532.48 302.59 532.63"
    />
    <polygon
      className={styles.cls4}
      points="284.25 529.22 283.81 553.85 283.37 529.22 284.25 529.22"
    />
    <polygon
      className={styles.cls4}
      points="268.79 524.63 268.55 525.03 268.6 524.84 268.17 546.38 267.74 524.84 267.74 524.74 267.79 524.64 268.01 524.24 268.79 524.63"
    />
    <polygon
      className={styles.cls4}
      points="255.68 518.58 255.24 537.57 254.8 518.58 255.68 518.58"
    />
    <ellipse
      className={styles.cls2}
      cx="375.54"
      cy="479.52"
      rx="135.22"
      ry="31.94"
    />
    <path
      className={styles.cls3}
      d="M541.22,604.26c0,17.64,60.54,31.94,135.22,31.94s135.22-14.3,135.22-31.94V578.58c0,17.64-60.54,31.94-135.22,31.94s-135.22-14.3-135.22-31.94Z"
      transform="translate(-300.9 -99.06)"
    />
    <polygon
      className={styles.cls4}
      points="506.71 487.67 506.27 508.74 505.83 487.67 506.71 487.67"
    />
    <polygon
      className={styles.cls4}
      points="495.66 494.35 495.22 516.69 494.78 494.35 495.66 494.35"
    />
    <polygon
      className={styles.cls4}
      points="481.26 500.19 480.82 521.69 480.38 500.19 481.26 500.19"
    />
    <polygon
      className={styles.cls4}
      points="463.75 503.88 463.5 504.49 463.52 504.36 463.09 527.31 462.67 504.36 462.66 504.29 462.69 504.23 462.91 503.61 463.75 503.88"
    />
    <polygon
      className={styles.cls4}
      points="443.92 507.07 443.48 532.97 443.04 507.07 443.92 507.07"
    />
    <polygon
      className={styles.cls4}
      points="423.48 509.37 423.04 535.24 422.6 509.37 423.48 509.37"
    />
    <polygon
      className={styles.cls4}
      points="400.95 511.04 400.51 536.7 400.07 511.04 400.95 511.04"
    />
    <polygon
      className={styles.cls4}
      points="375.71 511.66 375.27 537.32 374.83 511.66 375.71 511.66"
    />
    <polygon
      className={styles.cls4}
      points="352.15 511.17 351.68 512.61 351.69 512.5 351.28 535.24 350.87 512.5 350.87 512.44 350.89 512.38 351.3 510.92 352.15 511.17"
    />
    <polygon
      className={styles.cls4}
      points="332.95 509.99 332.51 533.61 332.07 509.99 332.95 509.99"
    />
    <polygon
      className={styles.cls4}
      points="315.22 508.12 314.78 532.59 314.34 508.12 315.22 508.12"
    />
    <polygon
      className={styles.cls4}
      points="295.59 505.06 295.37 506.31 295.38 506.24 294.96 529.4 294.55 506.24 294.54 506.21 294.55 506.17 294.72 504.91 295.59 505.06"
    />
    <polygon
      className={styles.cls4}
      points="277.25 501.65 276.81 526.28 276.37 501.65 277.25 501.65"
    />
    <polygon
      className={styles.cls4}
      points="261.79 497.06 261.55 497.46 261.6 497.27 261.17 518.8 260.74 497.27 260.74 497.16 260.79 497.07 261.01 496.66 261.79 497.06"
    />
    <polygon
      className={styles.cls4}
      points="248.68 491.01 248.24 510 247.8 491.01 248.68 491.01"
    />
    <ellipse
      className={styles.cls2}
      cx="382.54"
      cy="453.03"
      rx="135.22"
      ry="31.94"
    />
    <path
      className={styles.cls3}
      d="M548.22,577.77c0,17.64,60.54,31.94,135.22,31.94s135.22-14.3,135.22-31.94V552.09c0,17.64-60.54,31.94-135.22,31.94s-135.22-14.3-135.22-31.94Z"
      transform="translate(-300.9 -99.06)"
    />
    <polygon
      className={styles.cls4}
      points="513.71 461.18 513.27 482.25 512.83 461.18 513.71 461.18"
    />
    <polygon
      className={styles.cls4}
      points="502.66 467.86 502.22 490.2 501.78 467.86 502.66 467.86"
    />
    <polygon
      className={styles.cls4}
      points="488.26 473.7 487.82 495.2 487.38 473.7 488.26 473.7"
    />
    <polygon
      className={styles.cls4}
      points="470.75 477.39 470.5 478 470.52 477.87 470.09 500.82 469.67 477.87 469.66 477.8 469.69 477.74 469.91 477.12 470.75 477.39"
    />
    <polygon
      className={styles.cls4}
      points="450.92 480.58 450.48 506.48 450.05 480.58 450.92 480.58"
    />
    <polygon
      className={styles.cls4}
      points="430.48 482.88 430.04 508.75 429.6 482.88 430.48 482.88"
    />
    <polygon
      className={styles.cls4}
      points="407.95 484.55 407.51 510.21 407.07 484.55 407.95 484.55"
    />
    <polygon
      className={styles.cls4}
      points="382.71 485.17 382.27 510.83 381.83 485.17 382.71 485.17"
    />
    <polygon
      className={styles.cls4}
      points="359.15 484.68 358.68 486.12 358.7 486.01 358.28 508.75 357.87 486.01 357.87 485.95 357.89 485.89 358.3 484.43 359.15 484.68"
    />
    <polygon
      className={styles.cls4}
      points="339.95 483.5 339.51 507.12 339.07 483.5 339.95 483.5"
    />
    <polygon
      className={styles.cls4}
      points="322.22 481.63 321.78 506.1 321.34 481.63 322.22 481.63"
    />
    <polygon
      className={styles.cls4}
      points="302.59 478.57 302.37 479.82 302.38 479.75 301.96 502.91 301.55 479.75 301.55 479.73 301.55 479.68 301.72 478.42 302.59 478.57"
    />
    <polygon
      className={styles.cls4}
      points="284.25 475.16 283.81 499.79 283.37 475.16 284.25 475.16"
    />
    <polygon
      className={styles.cls4}
      points="268.79 470.57 268.55 470.97 268.6 470.78 268.17 492.31 267.74 470.78 267.74 470.67 267.79 470.58 268.01 470.17 268.79 470.57"
    />
    <polygon
      className={styles.cls4}
      points="255.68 464.52 255.24 483.51 254.8 464.52 255.68 464.52"
    />
    <ellipse
      className={styles.cls2}
      cx="379.74"
      cy="425.12"
      rx="135.22"
      ry="31.94"
    />
    <path
      className={styles.cls3}
      d="M545.42,549.87c0,17.64,60.54,31.94,135.22,31.94s135.22-14.3,135.22-31.94V524.18c0,17.64-60.54,31.94-135.22,31.94s-135.22-14.3-135.22-31.94Z"
      transform="translate(-300.9 -99.06)"
    />
    <polygon
      className={styles.cls4}
      points="510.91 433.28 510.47 454.35 510.03 433.28 510.91 433.28"
    />
    <polygon
      className={styles.cls4}
      points="499.86 439.95 499.42 462.3 498.98 439.95 499.86 439.95"
    />
    <polygon
      className={styles.cls4}
      points="485.46 445.79 485.02 467.3 484.58 445.79 485.46 445.79"
    />
    <polygon
      className={styles.cls4}
      points="467.95 449.48 467.7 450.1 467.72 449.96 467.29 472.91 466.86 449.96 466.86 449.89 466.88 449.83 467.11 449.21 467.95 449.48"
    />
    <polygon
      className={styles.cls4}
      points="448.12 452.68 447.68 478.57 447.24 452.68 448.12 452.68"
    />
    <polygon
      className={styles.cls4}
      points="427.68 454.97 427.24 480.84 426.8 454.97 427.68 454.97"
    />
    <polygon
      className={styles.cls4}
      points="405.15 456.64 404.71 482.31 404.27 456.64 405.15 456.64"
    />
    <polygon
      className={styles.cls4}
      points="379.91 457.27 379.47 482.92 379.03 457.27 379.91 457.27"
    />
    <polygon
      className={styles.cls4}
      points="356.35 456.77 355.88 458.22 355.89 458.1 355.48 480.85 355.07 458.1 355.07 458.05 355.09 457.99 355.5 456.52 356.35 456.77"
    />
    <polygon
      className={styles.cls4}
      points="337.15 455.6 336.71 479.21 336.27 455.6 337.15 455.6"
    />
    <polygon
      className={styles.cls4}
      points="319.42 453.72 318.98 478.2 318.54 453.72 319.42 453.72"
    />
    <polygon
      className={styles.cls4}
      points="299.79 450.67 299.57 451.92 299.58 451.84 299.16 475 298.74 451.84 298.74 451.82 298.75 451.77 298.92 450.51 299.79 450.67"
    />
    <polygon
      className={styles.cls4}
      points="281.45 447.25 281.01 471.88 280.57 447.25 281.45 447.25"
    />
    <polygon
      className={styles.cls4}
      points="265.99 442.67 265.75 443.07 265.8 442.87 265.37 464.41 264.94 442.87 264.94 442.77 264.98 442.68 265.21 442.27 265.99 442.67"
    />
    <polygon
      className={styles.cls4}
      points="252.87 436.62 252.43 455.6 252 436.62 252.87 436.62"
    />
    <ellipse
      className={styles.cls2}
      cx="386.45"
      cy="398.78"
      rx="135.22"
      ry="31.94"
    />
    <path
      className={styles.cls3}
      d="M552.13,523.52c0,17.64,60.54,31.94,135.22,31.94s135.22-14.3,135.22-31.94V497.83c0,17.64-60.54,31.94-135.22,31.94s-135.22-14.3-135.22-31.94Z"
      transform="translate(-300.9 -99.06)"
    />
    <polygon
      className={styles.cls4}
      points="517.62 406.93 517.18 428 516.75 406.93 517.62 406.93"
    />
    <polygon
      className={styles.cls4}
      points="506.57 413.61 506.13 435.95 505.69 413.61 506.57 413.61"
    />
    <polygon
      className={styles.cls4}
      points="492.18 419.44 491.74 440.95 491.3 419.44 492.18 419.44"
    />
    <polygon
      className={styles.cls4}
      points="474.66 423.14 474.41 423.75 474.43 423.62 474 446.56 473.58 423.62 473.58 423.55 473.6 423.49 473.82 422.87 474.66 423.14"
    />
    <polygon
      className={styles.cls4}
      points="454.84 426.33 454.4 452.23 453.96 426.33 454.84 426.33"
    />
    <polygon
      className={styles.cls4}
      points="434.39 428.62 433.95 454.49 433.51 428.62 434.39 428.62"
    />
    <polygon
      className={styles.cls4}
      points="411.86 430.29 411.43 455.96 410.99 430.29 411.86 430.29"
    />
    <polygon
      className={styles.cls4}
      points="386.62 430.92 386.18 456.58 385.75 430.92 386.62 430.92"
    />
    <polygon
      className={styles.cls4}
      points="363.06 430.42 362.59 431.87 362.61 431.75 362.19 454.5 361.78 431.75 361.78 431.7 361.8 431.64 362.22 430.18 363.06 430.42"
    />
    <polygon
      className={styles.cls4}
      points="343.86 429.25 343.42 452.87 342.98 429.25 343.86 429.25"
    />
    <polygon
      className={styles.cls4}
      points="326.13 427.37 325.69 451.85 325.25 427.37 326.13 427.37"
    />
    <polygon
      className={styles.cls4}
      points="306.5 424.32 306.28 425.57 306.29 425.5 305.87 448.66 305.46 425.5 305.46 425.47 305.46 425.42 305.63 424.16 306.5 424.32"
    />
    <polygon
      className={styles.cls4}
      points="288.17 420.91 287.73 445.53 287.29 420.91 288.17 420.91"
    />
    <polygon
      className={styles.cls4}
      points="272.7 416.32 272.46 416.72 272.51 416.52 272.08 438.06 271.65 416.52 271.65 416.42 271.7 416.33 271.92 415.92 272.7 416.32"
    />
    <polygon
      className={styles.cls4}
      points="259.59 410.27 259.15 429.25 258.71 410.27 259.59 410.27"
    />
    <ellipse
      className={styles.cls2}
      cx="382.54"
      cy="371.2"
      rx="135.22"
      ry="31.94"
    />
    <path
      className={styles.cls3}
      d="M548.22,496c0,17.64,60.54,31.94,135.22,31.94S818.66,513.59,818.66,496V470.26c0,17.64-60.54,31.94-135.22,31.94s-135.22-14.3-135.22-31.94Z"
      transform="translate(-300.9 -99.06)"
    />
    <polygon
      className={styles.cls4}
      points="513.71 379.36 513.27 400.43 512.83 379.36 513.71 379.36"
    />
    <polygon
      className={styles.cls4}
      points="502.66 386.03 502.22 408.38 501.78 386.03 502.66 386.03"
    />
    <polygon
      className={styles.cls4}
      points="488.26 391.87 487.82 413.38 487.38 391.87 488.26 391.87"
    />
    <polygon
      className={styles.cls4}
      points="470.75 395.56 470.5 396.18 470.52 396.05 470.09 418.99 469.67 396.05 469.66 395.97 469.69 395.91 469.91 395.29 470.75 395.56"
    />
    <polygon
      className={styles.cls4}
      points="450.92 398.76 450.48 424.65 450.05 398.76 450.92 398.76"
    />
    <polygon
      className={styles.cls4}
      points="430.48 401.05 430.04 426.92 429.6 401.05 430.48 401.05"
    />
    <polygon
      className={styles.cls4}
      points="407.95 402.72 407.51 428.39 407.07 402.72 407.95 402.72"
    />
    <polygon
      className={styles.cls4}
      points="382.71 403.35 382.27 429 381.83 403.35 382.71 403.35"
    />
    <polygon
      className={styles.cls4}
      points="359.15 402.85 358.68 404.3 358.7 404.18 358.28 426.93 357.87 404.18 357.87 404.13 357.89 404.06 358.3 402.61 359.15 402.85"
    />
    <polygon
      className={styles.cls4}
      points="339.95 401.68 339.51 425.29 339.07 401.68 339.95 401.68"
    />
    <polygon
      className={styles.cls4}
      points="322.22 399.8 321.78 424.28 321.34 399.8 322.22 399.8"
    />
    <polygon
      className={styles.cls4}
      points="302.59 396.75 302.37 398 302.38 397.92 301.96 421.09 301.55 397.92 301.55 397.9 301.55 397.85 301.72 396.59 302.59 396.75"
    />
    <polygon
      className={styles.cls4}
      points="284.25 393.33 283.81 417.96 283.37 393.33 284.25 393.33"
    />
    <polygon
      className={styles.cls4}
      points="268.79 388.75 268.55 389.15 268.6 388.95 268.17 410.49 267.74 388.95 267.74 388.85 267.79 388.76 268.01 388.35 268.79 388.75"
    />
    <polygon
      className={styles.cls4}
      points="255.68 382.69 255.24 401.68 254.8 382.69 255.68 382.69"
    />
    <path
      className={styles.cls5}
      d="M818.66,607.47v-1.32c0-3.56-2.46-7-7-10.17v-8c4.54-3.19,7-6.61,7-10.17V552.09a9.93,9.93,0,0,0-2.8-6.5V533.48c4.35-3.13,6.71-6.48,6.71-10V497.83c0-2.63-1.36-5.19-3.91-7.64V470.26c0-6.36-7.89-12.3-21.48-17.28-51.57,3.12-109.34-2.56-143.92,44.76-20,26-30,57-32,89-1.3,27.12-7.59,57.57-4.89,85.44,22.21,3.78,49.45,6,78.9,6,74.68,0,135.22-14.3,135.22-31.94V620.55C830.49,615.89,826.26,611.46,818.66,607.47Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls6}
      d="M801.26,649.74c-4-8,5-18,4-28-1-3-7-5-8-9-6-14,6-30-2-42-9-14-2-29-5-43-1-3-6-4-6-7-.82-26.05-15.55-56.08-39.89-65.26-34.86,2.77-68.12,10.79-91.11,42.26-20,26-30,57-32,89-1.3,27.12-7.59,57.57-4.89,85.44,22.21,3.78,49.45,6,78.9,6,42.43,0,80.28-4.62,105.08-11.84C803.26,661.23,804.46,655.7,801.26,649.74Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls6}
      d="M745.26,662.74c1-3-3-5-3-7,0-12,2-18-2-28-6-12,6-29,3-45,0-2-7-4-7-5-2-11,1-22,2-33,1-18-9-34-8-51,.69-12.46,1.86-25.87,6.83-37.59-32.18,3.34-62.44,12.32-83.83,41.59-20,26-30,57-32,89-1.3,27.12-7.59,57.57-4.89,85.44,22.21,3.78,49.45,6,78.9,6,15.49,0,30.37-.62,44.23-1.75a45.13,45.13,0,0,1,2.76-13.69Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls7}
      d="M537.1,722.08c3.8-1,7.68-2,11-4.09s6.14-5.4,6.59-9.31a5.64,5.64,0,0,0-1.22-4.59c-1.41-1.47-3.69-1.6-5.73-1.6-2.66,0-5.33,0-8,.14a42.62,42.62,0,0,0-9.5,1.12,48.32,48.32,0,0,0-10.8,4.82l-43,23.56c-.75.41-1.6,1-1.57,1.84a1.88,1.88,0,0,0,.52,1.12c2.93,3.37,9.08.74,12.48-.15l16.28-4.26Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls8}
      d="M637.62,726.55a121.05,121.05,0,0,0,13.57-4.35,65.47,65.47,0,0,0,18.63-11.53,3.86,3.86,0,0,0,1-1.12,3.28,3.28,0,0,0-.26-2.91c-1.59-3.1-5.23-4.7-8.71-4.83s-6.87,1-10.19,2c-25,7.88-50.89,14.55-72.68,29.08a5.72,5.72,0,0,0-2.34,2.36c-.75,1.85.73,5,2.65,4.61,1.71-.38,5.81-.5,7.79-.93q8.58-1.84,17.13-3.85Q621,731.12,637.62,726.55Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls9}
      d="M658.07,665.14c.49,9.54-1.11,19.32-5.69,27.7-3.23,5.89-7.81,10.91-12.06,16.13-3.31,4.06-6.46,8.3-10.28,11.9-10,9.41-23.6,13.76-36.89,17.14-5.48,1.39-11.12,2.67-16.74,2s-11.31-3.48-13.87-8.52c-3.09-6-.74-13.95,4.42-18.36,6.26-5.34,15.52-6.08,21.67-11.54,4.94-4.37,7.08-11.1,8.11-17.62s1.16-13.21,3.08-19.53c2-6.47,5.72-12.24,9.81-17.62,4.32-5.68,9.19-11.13,15.38-14.66,7.23-4.13,21.89-6.53,26.76,2.64,1.72,3.23,2.1,8.08,3.08,11.6A86.57,86.57,0,0,1,658.07,665.14Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls10}
      d="M658.07,665.14a86.57,86.57,0,0,0-3.22-18.69c-1-3.52-1.36-8.37-3.08-11.6-4.87-9.17-19.53-6.77-26.76-2.64-6.19,3.53-11.06,9-15.38,14.66-4.09,5.38-7.85,11.15-9.81,17.62a46,46,0,0,0-1.38,6.24c9.95-1,20.87,0,29.82,5,14.39,8,13.43,23.66,8.39,37.79,1.23-1.51,2.44-3,3.67-4.56,4.25-5.22,8.83-10.24,12.06-16.13C657,684.46,658.56,674.68,658.07,665.14Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls9}
      d="M550.73,691.07c-2.14,2.61-5,4.49-7.76,6.45-12.21,8.67-22.78,19.51-35.2,27.87s-27.64,14.19-42.28,11.06a10.83,10.83,0,0,1-4.25-1.66,8.47,8.47,0,0,1-2.69-3.63c-2-4.79-.78-10.6,2.51-14.61a19.87,19.87,0,0,1,13.56-6.8c2.14-.2,4.37-.13,6.34-1,2.8-1.21,4.5-4,6-6.69a239.09,239.09,0,0,0,13.65-29.3,59,59,0,0,1,3.65-8.3c3.68-6.38,10-11,17-13.35,6.61-2.23,17.15-4.27,22.85,1.16s9.85,15.79,10.63,23.25C555.29,681,554.26,686.76,550.73,691.07Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls11}
      d="M563.15,718.05a7.53,7.53,0,0,0,1.72,7.54,9.49,9.49,0,0,0,3.37,2.17,19.38,19.38,0,0,0,3.92,1,39.94,39.94,0,0,0,4.09.44c1.38.11,2.75.14,4.13.16a75.78,75.78,0,0,0,8.25-.35,69.19,69.19,0,0,0,31.13-10.4,74.52,74.52,0,0,0,23.3-23,147.85,147.85,0,0,0,7.9-14.55c1.19-2.5,2.42-5,3.58-7.49s2.27-5.06,3.5-7.55l.09,0c-1.1,2.55-2,5.16-3.1,7.72s-2.19,5.1-3.34,7.62-2.35,5-3.61,7.51-2.66,4.89-4.15,7.25a67.3,67.3,0,0,1-10.57,12.93,70.3,70.3,0,0,1-13.32,10c-2.39,1.41-4.87,2.68-7.38,3.86s-5.11,2.18-7.73,3.1a75.69,75.69,0,0,1-16.21,3.81,62.61,62.61,0,0,1-16.69-.31,18,18,0,0,1-4-1.21,9.93,9.93,0,0,1-3.49-2.42,7.62,7.62,0,0,1-1.43-7.93Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls11}
      d="M482.86,707.58a46.77,46.77,0,0,1,17.92,3.28,53.45,53.45,0,0,0-19.31-2.34Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls11}
      d="M582.82,705.34a83.13,83.13,0,0,1,15.29,1c2.59.41,5.17.94,7.71,1.58,1.27.32,2.54.67,3.79,1.05.63.19,1.26.38,1.88.59l.93.31c.29.09.7.18.75.54-.06-.35-.47-.42-.76-.51l-.94-.28c-.63-.19-1.26-.36-1.89-.53-1.27-.34-2.54-.65-3.82-.93-2.55-.56-5.13-1-7.72-1.33a99.88,99.88,0,0,0-16.75-.76Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls10}
      d="M632.91,709.57a58.68,58.68,0,0,1-5,4.3,82.12,82.12,0,0,1-8.71,5.71A106.33,106.33,0,0,1,608,724.89a84.68,84.68,0,0,1-13.33,4.05c-5.78,1.32-11.71,1.39-17.73,1.19a34.51,34.51,0,0,1-7.27-1.14,10.14,10.14,0,0,1-6.06-4.3,7.15,7.15,0,0,1-.85-6c-2.22,5.72-1.79,7.38-.8,11.48a10.41,10.41,0,0,0,2.29,3.94,14.81,14.81,0,0,0,2.8,2.49c2.73,2.07,6,2.9,9.35,3.44a30.19,30.19,0,0,0,6.36.05,33,33,0,0,0,4.92-.83c3.43-.74,6.68-1.5,10.08-2.49,3.66-1.06,7.24-2.1,10.89-3.43a88.32,88.32,0,0,0,10-4.35,55.91,55.91,0,0,0,13.43-10.09c.93-1,3.68-3.59,4.58-5.39,1.1-2.22,2.64-9.33,2.9-11.12.18-1.27-2,2.5-3,3.44C635.39,707,634.07,708.47,632.91,709.57Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls12}
      d="M554.73,675.53c-.78-7.46-5-17.92-10.63-23.25s-16.24-3.39-22.85-1.16c-7,2.35-13.33,7-17,13.35-1.51,2.62-2.37,3.46-3.46,6.29,0,.06.54.13.51.2,14.91,8.09,33.35,3.53,21.64,34.67h0s-5.38,3.92-6.13,4.52q-3,2.4-6.15,4.54c-1.07.73-2.16,1.43-3.26,2.11-.72.45-2.3,1.85-3.12,1.85-11.51,6.51-25.06,10.58-38,7.87A9.28,9.28,0,0,1,461,724c-1.91-2.16-1.27-6.11.72-8.2a14.89,14.89,0,0,0-4.16,11.31,11.62,11.62,0,0,0,1.06,4.23c2.11,4.17,3.65,4.26,6.2,4.92a34.14,34.14,0,0,0,8.16,1.06c15.25.16,28.81-7.28,40.8-16.25,2-1.47,5.34-4.14,5.43-4.33-.13.34.14-.35,0,0,7.89-6.44,15.44-13.34,23.74-19.24,2.75-2,5.62-3.84,7.76-6.45C554.26,686.76,555.29,681,554.73,675.53Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls11}
      d="M461.78,715.85a18.84,18.84,0,0,0-1.58,3.34,4.64,4.64,0,0,0,.17,3.46,5.78,5.78,0,0,0,2.5,2.44,13.79,13.79,0,0,0,3.4,1.22,46.56,46.56,0,0,0,14.67.25,57.39,57.39,0,0,0,14.3-3.77,74.16,74.16,0,0,0,13.09-7c8.26-5.48,15.63-12.15,23.07-18.71s15-13.14,23.44-18.41l.06.09c-8.09,5.77-15.4,12.48-22.81,19.09-3.69,3.32-7.45,6.59-11.32,9.72a115.52,115.52,0,0,1-12.15,8.68,101.94,101.94,0,0,1-13.25,6.86,61.77,61.77,0,0,1-14.31,4.27,41.86,41.86,0,0,1-15-.33,12.55,12.55,0,0,1-3.55-1.39,6.36,6.36,0,0,1-2.64-2.79,4.71,4.71,0,0,1-.4-1.91,5.43,5.43,0,0,1,.38-1.88,16.84,16.84,0,0,1,1.81-3.28Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls13}
      d="M581.78,500.51c-.18,1.81-.63,4.57,0,6.31.49,1.37,1.39,1.94,1.48,3.66,2.53,47,6.94,94.11,5.12,138.84-.41,10,3.71,16.09,6.67,18.3,2.69,2,3,3.86,9.11,3.37s11.77-3.33,17.12-6.31a265,265,0,0,0,27.12-17.32c2.86-2.08,5.77-4.33,7.39-7.48,3.31-6.42.29-14.08-1.62-21-9-32.82,8.11-68.71-1.31-101.43-.52-1.8-1.09-3.87-.1-5.46a6.43,6.43,0,0,1,2.75-2.08l59.55-29.95c11.07-5.57,23.37-12.65,26-24.77,1.76-8.11-1.58-16.71-7.12-22.89s-13-10.23-20.71-13.41c-40.66-16.91-83.22-7.63-122.72,7.38l-39.51,15c-27.75,10.55-56.18,21.5-78.28,41.32-5.24,4.69-10.16,10-12.92,16.45-2.66,6.19-3.16,13.08-3.25,19.83-.28,22.43,3.72,44.67,7.88,66.71,4,21.36,8.5,43.34,20.66,61.37,3,4.42,8.84,20.63,13.65,23,15.43,7.47,30.69-1.55,48.35-3.27,2.33-.23,5-.71,6.1-2.79a6.38,6.38,0,0,0,.35-3.68A34.85,34.85,0,0,0,545.65,642a23.61,23.61,0,0,1-3.54-4.71c-1.12-2.35-1.14-5-1.28-7.63-.62-11-3.84-21.7-7-32.26l-19.15-63.26c-.5-1.68-1-3.54-.27-5.13.84-1.82,3-2.6,4.89-3.2l59.43-18.65a4.14,4.14,0,0,1,3.12-.14"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls14}
      d="M581.78,500.51l0,2.75c1-3.29-4.34-7.1-2.24-10.13,6-9,1.62-17.42,7.62-26.42,7.81-12.14,29.31-14.36,29.64-27.55C576.89,456.7,538.59,473,504.26,500.74c-18,15-3,38,1,58,8.75,38.93-1.42,75-2,112.87,13.91,4,27.88-3.46,43.8-5,2.33-.23,5-.71,6.1-2.79a6.38,6.38,0,0,0,.35-3.68A34.85,34.85,0,0,0,545.65,642a23.61,23.61,0,0,1-3.54-4.71c-1.12-2.35-1.14-5-1.28-7.63-.62-11-3.84-21.7-7-32.26l-19.15-63.26c-.5-1.68-1-3.54-.27-5.13.84-1.82,3-2.6,4.89-3.2l59.43-18.65a11.26,11.26,0,0,1,3.12-.14c.17-.76-.55-1.76-.35-2.52C581.46,503.08,581.67,501.62,581.78,500.51Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls14}
      d="M581.46,504.45c-.2.76-.38,1.51-.55,2.28a2.15,2.15,0,0,1,.9.24v-.09a.14.14,0,0,1,0-.06A7,7,0,0,1,581.46,504.45Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls14}
      d="M581.78,500.51c-.18,1.81-.63,4.57,0,6.31a.14.14,0,0,0,0,.06"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls15}
      d="M536.27,639.08c-2.18,1.73-4.75,2.91-7.11,4.39s-4.62,3.37-5.66,6a61.63,61.63,0,0,0,17.61-7.75c.78-.5,1.62-1.15,1.67-2.07a3.22,3.22,0,0,0-.37-1.41c-.58-1.39-.6-3.59-1.45-4.72A25.62,25.62,0,0,1,536.27,639.08Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls15}
      d="M612.72,622.93c-1.84,1.44-4.32,1.64-6.65,1.78l-7.87.49c-2.23.13-4.56.25-6.59-.67-1.19-.54-4.53-3-1.77-3.74,1.69-.43,5.53,1.52,7.34,1.86A46.33,46.33,0,0,0,612.72,622.93Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls16}
      d="M659.13,483.78c-.64,2.48-1.37,4.93-2,7.4s-1.33,4.93-1.85,7.42-1,5-1.32,7.52c-.17,1.27-.41,2.54-.67,3.79s-.63,2.49-.85,3.75l-.3.07a5,5,0,0,1-1.09-2.38c-.12-.89-.08-1.72-.23-2.53l-1.16-4.93a92.38,92.38,0,0,1-1.69-10.07,89.2,89.2,0,0,1-.5-10.2,84.32,84.32,0,0,1,.68-10.17h.09c-.09,3.39-.07,6.77,0,10.15s.4,6.74.75,10.1.84,6.69,1.49,10c.28,1.68.69,3.28.93,5,.1.88,0,1.75.06,2.55a4.08,4.08,0,0,0,.9,2.23l-.3.07a41.42,41.42,0,0,0,1.28-7.54c.1-1.27.19-2.55.34-3.84s.42-2.54.67-3.81c.57-2.51,1.27-5,2.06-7.42s1.66-4.85,2.61-7.23Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls16}
      d="M634.3,483.07c2.24,2,4.38,4.14,6.44,6.33,1,1.12,2,2.19,3,3.39a25.06,25.06,0,0,1,2.48,3.83,69.09,69.09,0,0,1,3.53,8.33c1,2.84,2,5.69,2.94,8.52L652,514a26,26,0,0,1-3.78-2.56,8.35,8.35,0,0,1-1.37-1.93l-1.11-1.93a4.9,4.9,0,0,0-3.46-2.07,10.23,10.23,0,0,0-4.36.23l0-.09a9.3,9.3,0,0,1,4.47-.68,5.33,5.33,0,0,1,4.09,2.16l1.25,1.87a7.86,7.86,0,0,0,1.26,1.67,12.87,12.87,0,0,0,1.71,1.27l1.86,1.24-.73.57c-.94-2.87-1.65-5.8-2.46-8.68a29.26,29.26,0,0,0-3.57-8.2,62.69,62.69,0,0,0-5.63-6.93c-2-2.26-3.94-4.53-5.88-6.83Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls16}
      d="M510.39,503.61a77.44,77.44,0,0,1,2.36,14.15c.17,2.39.25,4.78.51,7.15s.55,4.75.86,7.12a.36.36,0,0,1-.3.4h-.12c-1.87-.23-3.73-.52-5.58-.81l-5.54-.81c-1.85-.24-3.7-.5-5.56-.59a49.76,49.76,0,0,0-5.6-.09V530a30.36,30.36,0,0,1,5.63-.56,46.73,46.73,0,0,1,5.67.31c1.87.19,3.73.52,5.58.86s3.69.71,5.52,1.07l-.42.4c-.12-2.39-.23-4.78-.44-7.16s-.58-4.74-.94-7.1-.62-4.73-.91-7.09l-.81-7.12Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls16}
      d="M501.51,508.45c1.28,1.82,2.42,3.73,3.53,5.66s2.14,3.89,3.1,5.89l2.78,6c.95,2,2,4,3.06,5.9l-.34.34a55.18,55.18,0,0,1-5.82-3.26c-1.87-1.18-3.73-2.38-5.57-3.58-3.69-2.41-7.36-4.85-11.5-6.39l0-.1a26.38,26.38,0,0,1,6.26,2.28,60.11,60.11,0,0,1,5.79,3.31c1.87,1.2,3.67,2.47,5.5,3.71s3.64,2.47,5.54,3.58l-.34.34c-.93-2-1.89-4-2.92-6s-2.16-3.88-3.22-5.82-2-3.93-3-5.91l-2.93-6Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls14}
      d="M489.07,482.74c-4.26,6.26-2.71,15.5-7.07,21.67a12,12,0,0,1-12,4.88,6.5,6.5,0,0,1-3.53-1.81,9.74,9.74,0,0,1-1.95-4.58c-1.05-4.6-1.94-9.33-1.44-14a48,48,0,0,1,2.3-9.29,26.19,26.19,0,0,1,2-5.06,18.88,18.88,0,0,1,4.1-4.71,25.88,25.88,0,0,1,20.05-6.65c5.23.49,9.78,4.31,6.32,10.31C495.72,477.25,491.51,479.17,489.07,482.74Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls14}
      d="M733.93,432.25c-5.54-6.17-13-10.23-20.71-13.41-21.67-9-43.88-10.58-65.87-7.81,5.95,4.65,12.45,12.59,17.92,20.13-.55.18-1.09.39-1.63.6-22.28,8.77-46.22,13.84-66.77,26.13-1.33.8-5.27,3.64-2.43,4.47,2.09.61,7-1.88,9-2.5l19.26-5.67c4-1.18,8-2.36,12.06-3.14,13-2.49,27.25-1.06,38.87-7.45,2,3.09,3.31,5.42,3.85,6.43,9.47,17.91-9.27,22.71-20.27,29.71-56.51,50.34-22.86,148.74-43.73,188.82,8.55-3.71,27.06-15.51,34.88-21.2,2.86-2.08,5.77-4.33,7.39-7.48,3.31-6.42.29-14.08-1.62-21-9-32.82,8.11-68.71-1.31-101.43-.52-1.8-1.09-3.87-.1-5.46a6.43,6.43,0,0,1,2.75-2.08l59.55-29.95c11.07-5.57,23.37-12.65,26-24.77C742.81,447,739.47,438.43,733.93,432.25Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls17}
      d="M670.16,156.27c-1.93,3.88-5.86,6.42-9.93,7.91s-8.42,2.1-12.6,3.23c-14,3.79-26.83,15.05-28.28,29.45-1,9.95,3.39,19.61,4.36,29.55.83,8.47-.75,17.46,2.76,25.21,2.77,6.12,8.28,10.46,13.6,14.56a12.26,12.26,0,0,0,5.25,2.83c3,.5,5.89-1.26,8.44-2.94,7.84-5.17,15.73-10.39,22.48-16.92s12.35-14.55,14.6-23.68a27,27,0,0,0,.79-9.3c-.59-5.54-3.34-10.58-5.61-15.67-6.93-15.5-9.77-32.46-12.54-49.21A16,16,0,0,0,670.16,156.27Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls18}
      d="M675.21,172.94c-.12,2.35-.27,4.82-1.51,6.83-1.41,2.25-3.94,3.51-6.34,4.65C656.29,189.68,645.08,195,633,197.26c-2.82.53-5.71.91-8.31,2.13-2,1-4.25,2.06-6.48,2.17a10.53,10.53,0,0,0,5.42,1.69c7.67.33,15.51-.51,22.93,1.49a31.23,31.23,0,0,1,20.82,19,41,41,0,0,0,2.53,6.51,28.36,28.36,0,0,0,4,6,17.42,17.42,0,0,0,1.69,1.74c.49.17,1.8-.8,2.27-1,2.57-1.17,3.84-3.71,5.67-5.86a19.43,19.43,0,0,0,2.92-5.42l3.61-8.83a10.69,10.69,0,0,0,1-3.49c.07-1.78-.8-3.43-1.52-5.06-3.2-7.32-6.22-13.72-8.41-20.9-1.09-3.56-1.85-6.76-2.8-10.35-.56-2.09-2.08-8.93-2.56-12.27C675.52,166.68,675.31,171.08,675.21,172.94Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls19}
      d="M677,162.2a257,257,0,0,1-2.35,27.56,12.32,12.32,0,0,1-1.17,4.32c-1.21,2.2-3.57,3.47-5.82,4.56a155,155,0,0,1-50.86,14.58,36.14,36.14,0,0,1-10.89-.06,14.44,14.44,0,0,1-9.15-5.48c-2.34-3.34-2.63-7.66-2.75-11.74a295.73,295.73,0,0,1,5.77-67.69c1.78-8.81,13-13.12,21.73-15.24s17.85-1.83,26.83-1.4a36.78,36.78,0,0,1,9.86,1.37,12.8,12.8,0,0,1,7.64,6c1.41,2.8,1.35,6.07,1.61,9.19a42.57,42.57,0,0,0,2.2,10.46,4.29,4.29,0,0,0,1,1.81c1.41,1.3,3.64.34,5.56.36,2.43,0,4.57,1.84,5.67,4a17.93,17.93,0,0,1,1.48,7.11c.15,2.69.17,5.55-1.19,7.88S679.3,163.63,677,162.2Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls18}
      d="M656.53,200.72c5-3.85,5.1-6.49,4.77-11.9-.26-4.27.19-3.72-1.7-7.57a18.16,18.16,0,0,1-.75-5.82,24.6,24.6,0,0,1,3.34-8.48c1.47-2.45,1.15-13.75,1-17.18s.42-8,.07-11.29a29.24,29.24,0,0,0-2.32-8.12c-3.9-9.39-9.93-13.28-14.86-10.88-7.11,3.46-13.7,4.52-21.56,5.37-.4-4.59,2.93-8.89,7.09-10.89s9-2.11,13.55-1.8c6.29.43,12.79,1.69,17.81,5.51,3.92,3,6.6,7.28,9.19,11.47l4.54,7.33c.9,1.46-2.73,4.42-1,4.35,4.33-.15,6.68,4.08,7,6.55.63,4.19,1.29,9.19-.62,12.44-.36.63-1.53,2.85-3.11,2.92a4,4,0,0,1-2.06-.53c0,4.88-.21,11.16-.72,16.34-.43,4.32-.58,8.3-1.42,12.57-.26,1.34-1.7,3-2.43,4.15-1.25,2-3.52,3-5.68,3.86q-5.22,2.12-10.54,3.93c-2.08.72-19,7.84-21,6.86C647.37,205.47,653.64,203,656.53,200.72Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls20}
      d="M594.15,132.16c-.27-2.12-.44-6.69.31-8.69,1.06-2.83,2.14-5.12,4.57-6.89,4.26-3.09,7.43-7.67,11.69-10.76a42.08,42.08,0,0,1,7.52-4.71c7.76-3.46,16.75-2,24.93.31s16.21,5.32,22.59,10.85A36.66,36.66,0,0,1,678.06,136a22.58,22.58,0,0,1-.78,4.93,3.54,3.54,0,0,0-2.37.24c-2.17,1-3.4,3.38-3.86,5.73a25.63,25.63,0,0,0,0,7.16,4,4,0,0,1-.19,2.07c-.73,1.54-3.14,1.43-4.32.2s-1.44-3.06-1.65-4.76l-2.22-17.94c-.52-4.19-1.06-8.49-3-12.24s-5.56-6.9-9.74-7c-3.1-.08-6,1.47-8.66,3-7,4.08-14.34,8.72-22.41,8.28-4.16-.23-8.13-1.83-12.23-2.59a5.72,5.72,0,0,0-4.18.37c-1.29.82-1.82,2.41-2.2,3.9-.6,2.33-3,14.83-3,14.83C595.4,140.8,594.43,134.37,594.15,132.16Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls21}
      d="M678.06,136a36.66,36.66,0,0,0-12.3-23.76c-6.38-5.53-14.48-8.6-22.59-10.85s-16.72-3.68-24.34-.55c8.61,4.47,11.65,14.57,8.59,23.59,4.83-1.6,9.41-4.41,13.86-7,2.68-1.57,5.56-3.12,8.66-3,4.18.1,7.81,3.25,9.74,7s2.47,8,3,12.24l2.22,17.94c.21,1.7.48,3.53,1.65,4.76s3.59,1.34,4.32-.2a4,4,0,0,0,.19-2.07,25.63,25.63,0,0,1,0-7.16c.46-2.35,1.69-4.76,3.86-5.73a3.54,3.54,0,0,1,2.37-.24A22.58,22.58,0,0,0,678.06,136Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls22}
      d="M676.69,145.94a8.88,8.88,0,0,0-1.75,4.33,16.19,16.19,0,0,0,0,2.31c0,.77.13,1.53.24,2.35,0,0,0,0,0,0h0a5.21,5.21,0,0,1-.93-2.3,6.53,6.53,0,0,1,.15-2.49,8.37,8.37,0,0,1,2.29-4.28h0S676.7,145.93,676.69,145.94Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls22}
      d="M616.06,199.22c2.58-.67,5.13-1.34,7.65-2.09s5-1.6,7.44-2.54,4.85-2,7.24-3.06,4.73-2.31,7.1-3.53a41.41,41.41,0,0,1-6.55,4.59A51.43,51.43,0,0,1,624,198.28,41.59,41.59,0,0,1,616.06,199.22Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls22}
      d="M607.92,150.31c.31,3.52.68,7,1.12,10.54s.9,7,1.46,10.49q.42,2.63.91,5.22c.16.84.29,1.79.47,2.53a1.72,1.72,0,0,0,1.15,1.39,10.65,10.65,0,0,0,2.42.45c.88.13,1.75.24,2.63.39,1.75.27,3.5.56,5.24.94a.08.08,0,0,1,.06.09.08.08,0,0,1-.08.06c-1.78.06-3.55,0-5.32,0-.89,0-1.77-.07-2.66-.1a10.88,10.88,0,0,1-2.81-.36,3.34,3.34,0,0,1-1.43-.94,3.93,3.93,0,0,1-.74-1.49c-.23-1-.28-1.8-.43-2.7l-.63-5.28c-.4-3.52-.74-7-1-10.58s-.41-7.08-.51-10.62a.07.07,0,0,1,.07-.07A.07.07,0,0,1,607.92,150.31Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls22}
      d="M624.3,177.88a8.27,8.27,0,0,1,3.77,0,3,3,0,0,1,1.74,1,2.4,2.4,0,0,1,.41,1.94s0,0-.05,0,0,0,0,0a4.83,4.83,0,0,0-.93-1.45,4.34,4.34,0,0,0-1.34-.93,9.39,9.39,0,0,0-3.57-.48,0,0,0,0,1,0,0S624.28,177.89,624.3,177.88Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls23}
      d="M638.43,150.46c-.45.21-.21,2.85-.13,5.57,0,0,2.33-.31,4.11-.44a19.39,19.39,0,0,0,5.23-.68c1.1-.39,5-1.15,5-1.58-.17-1.2,0-3.49-.72-4a15.19,15.19,0,0,0-4.32-.48,28.39,28.39,0,0,0-9.13,1.61"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls24}
      d="M633.62,146.91c7.06-1.93,14-4.19,21-6.44.86-.28,1.83-.66,2.1-1.52.42-1.34-1.22-2.43-2.62-2.55-2.46-.21-4.82.91-7.07,1.95a67.32,67.32,0,0,1-14.21,4.58C628.51,143.89,628.91,148.2,633.62,146.91Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls24}
      d="M595.63,147.84a2.42,2.42,0,0,0-1,.27,2.7,2.7,0,0,0-.94,1.89,2.91,2.91,0,0,0,.09,2.06c.48.81,1.56.87,2.47.84l5.16-.16c1.9-.05,3.86-2.59,2.38-4.5S597.49,147.66,595.63,147.84Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls22}
      d="M630.38,198.79a7.6,7.6,0,0,0,3.3.37,3.71,3.71,0,0,0,1.33-.71,2.51,2.51,0,0,0,.91-1.24.06.06,0,0,1,.06,0s0,0,0,0a1.75,1.75,0,0,1-.47,1.8,2.63,2.63,0,0,1-1.76.75,6.72,6.72,0,0,1-3.42-.9,0,0,0,0,1,0-.05S630.36,198.79,630.38,198.79Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls22}
      d="M677.07,158.53a175,175,0,0,1-.84,23.12l-.58,5.76a19.16,19.16,0,0,1-1.12,5.74,10,10,0,0,1-4,4.31,40.28,40.28,0,0,1-5.19,2.65q-5.26,2.41-10.7,4.48c-3.62,1.35-7.3,2.55-11,3.63a153.12,153.12,0,0,1-22.71,4.52.05.05,0,1,1,0-.1,228.62,228.62,0,0,0,22.47-5.34c3.69-1.06,7.35-2.27,11-3.59s7.19-2.73,10.71-4.28a41.6,41.6,0,0,0,5.14-2.54,9.57,9.57,0,0,0,3.81-4,19.16,19.16,0,0,0,1.14-5.55l.65-5.75a176.1,176.1,0,0,0,1.2-23.07,0,0,0,1,1,.1,0Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls22}
      d="M646.84,152.34a3.4,3.4,0,0,1-3.47,2.95,3,3,0,0,1,.32-6.07A3.26,3.26,0,0,1,646.84,152.34Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls25}
      d="M638.3,156c2.44-.37,4.82-.68,7.19-1.14s4.7-1,7.11-1.56a21.87,21.87,0,0,1-6.94,2.5A22.18,22.18,0,0,1,638.3,156Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls22}
      d="M644.17,187.3a5,5,0,0,1,1.68,1.08,3.05,3.05,0,0,1,.63.8,2.25,2.25,0,0,1,.27,1l0,0h0c-.18-.29-.34-.57-.52-.82s-.34-.54-.55-.79a5.38,5.38,0,0,0-1.49-1.23s0,0,0,0Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls25}
      d="M652.79,149.55a29.12,29.12,0,0,0-8.44-.06,39,39,0,0,0-4.08.92,23.34,23.34,0,0,0-3.95,1.42.06.06,0,0,1-.07,0,.07.07,0,0,1,0-.06A9.87,9.87,0,0,1,640,149.5a16.46,16.46,0,0,1,4.28-.77,26.68,26.68,0,0,1,8.55.73,0,0,0,0,1,0,0S652.81,149.55,652.79,149.55Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls23}
      d="M596.54,158.37a21.6,21.6,0,0,1,.85,3.63,8.4,8.4,0,0,1,1.41-.09,9.57,9.57,0,0,0,3.32-.35c.73-.28,3.24-.74,3.25-1.11,0-1,.33-2.92,0-3.38a7.28,7.28,0,0,0-2.64-.58,10,10,0,0,0-4.89.94"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls22}
      d="M603.33,159.36a2.78,2.78,0,0,1-2.91,2.18,2.26,2.26,0,0,1-1.78-2.73,2.47,2.47,0,0,1,2.61-2.16A2.43,2.43,0,0,1,603.33,159.36Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls25}
      d="M597.39,162c1.4-.18,2.74-.27,4-.57s2.6-.7,4-1a10.94,10.94,0,0,1-3.83,1.67A8.86,8.86,0,0,1,597.39,162Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls25}
      d="M605.32,157.1a10.08,10.08,0,0,0-4.55-.38,11.16,11.16,0,0,0-2.15.66,13,13,0,0,0-2,1s0,0-.05,0,0,0,0,0a4.81,4.81,0,0,1,1.84-1.56,6.4,6.4,0,0,1,2.35-.58,9.37,9.37,0,0,1,4.63.83s0,0,0,0S605.34,157.11,605.32,157.1Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls26}
      d="M587.42,236.13a35.69,35.69,0,0,0-8.6,7.14c-3.58,4.47-5,10.25-6.25,15.84A830.55,830.55,0,0,0,555.9,364.75a8.42,8.42,0,0,0,.34,4.21,8.33,8.33,0,0,0,3.16,3.13,357.48,357.48,0,0,0,33.18,20,509.52,509.52,0,0,1,17-59.89,107.72,107.72,0,0,1-1.35,78.92c-1.84,4.38-5.13,5.58-3.63,10.09,22.4-.93,46.75,1.88,67.47,10.45,10.43,4.31,19.79,10.85,30,15.7s21.88,8,32.75,5c2.8-.78,5.61-2.05,7.41-4.34s2.42-5.61,2.88-8.64c4.17-27.72,8.35-55.48,9.74-83.47,1-19.17.63-38.61,5.24-57.24,2.13-8.59,5.32-17.17,4.76-26-.39-6.09-2.56-11.93-5.29-17.38C746.4,228.92,718.73,210.5,689.41,208a87.69,87.69,0,0,1-31.09,47.47c-3.59,2.77-7.94,5.39-12.37,4.46s-7.71-4.34-10.5-8.05-6.83-12.31-8.82-18.38c-1.16-3.52-4.28-15.08-4.28-15.08-2,.83-15.86,6.48-18.78,8.06Q595.3,231,587.42,236.13Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls27}
      d="M604.76,312.84c2.25,8.16,4.84,19.33,4.84,19.33-1.12,3.73-4.59,12.88-5.69,16.76-1.65,5.79-3.56,12.3-5.3,18.54A97.58,97.58,0,0,1,567,361.34c3.53-6.79,5.36-14.31,7.43-21.68a320,320,0,0,1,11.11-32.74c4.15-10.36,8.8-23.23,15.15-32.42.9,5.93-.22,13.34.46,19.4A123.47,123.47,0,0,0,604.76,312.84Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls27}
      d="M702.59,341c-.22,7.36-.51,14.79-2.52,21.87-4.21,14.82-15.4,26.49-26.4,37.28a32.58,32.58,0,0,1-4.81,4.13c-4.92,3.23-11.14,3.59-16.65,5.67-4.69,1.76-8.81,4.74-13.27,7a48.67,48.67,0,0,1-29.77,4.69c-1.87-.3-4.1-1.09-4.4-2.95a4.84,4.84,0,0,1,.62-2.68l4-9a31.64,31.64,0,0,1,3.86-7c4-4.95,10.35-7.07,16.12-9.67,12.38-5.57,23.12-14.32,32.25-24.37s16.7-21.37,23.85-32.91c6.14-9.9,12.19-20.77,11.52-32.4-.15-2.61-.64-5.26,0-7.8.79-3.34,5-9,6.06-3.23,1,5.34.1,11.68.09,17.11Q703.1,323.87,702.59,341Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls28}
      d="M764.85,272.62c-.39-6.09-2.56-11.93-5.29-17.38C746.4,228.92,718.73,210.5,689.41,208A88,88,0,0,1,675,238.33c18.18-1.54,40.23-4.93,49.26,10.41,19,32,3,76-20,105-18.16,22.7-33.44,47.11-44.14,73.67a122.78,122.78,0,0,1,12,4.22c10.43,4.31,19.79,10.85,30,15.7s21.88,8,32.75,5c2.8-.78,5.61-2.05,7.41-4.34s2.42-5.61,2.88-8.64c4.17-27.72,8.35-55.48,9.74-83.47,1-19.17.63-38.61,5.24-57.24C762.22,290,765.41,281.45,764.85,272.62Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls22}
      d="M710.54,280.4l-5.07,4.5-5.12,4.44c-1.71,1.47-3.34,3.05-5.17,4.37a46.47,46.47,0,0,1-5.72,3.68.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.09,46.56,46.56,0,0,1,4.82-4.81c1.69-1.51,3.57-2.76,5.37-4.12l5.43-4.06,5.47-4a.07.07,0,0,1,.11,0A.07.07,0,0,1,710.54,280.4Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls22}
      d="M609.53,332.2c-.49-1.13-.94-2.27-1.37-3.42s-.8-2.32-1.16-3.49a69.84,69.84,0,0,1-1.81-7.11l-.58-3.63c-.16-1.22-.32-2.43-.54-3.63-.4-2.42-.91-4.8-1.44-7.2s-1-4.8-1.32-7.22l-.49-3.65c-.15-1.21-.25-2.43-.36-3.65a89.08,89.08,0,0,1,.1-14.7.1.1,0,0,1,.09-.07.07.07,0,0,1,.07.07c.54,4.87,1,9.71,1.45,14.55l.64,7.26c.14,1.21.24,2.42.35,3.64s.23,2.42.34,3.64.26,2.42.43,3.63.41,2.4.65,3.6c.53,2.38,1.26,4.71,1.91,7.06.33,1.17.63,2.35.89,3.54s.56,2.36.82,3.55c.54,2.38,1,4.76,1.48,7.18a.07.07,0,0,1-.06.09A.08.08,0,0,1,609.53,332.2Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls22}
      d="M616,360.29c4.66,1.25,9.54,3.37,14,5a47.43,47.43,0,0,0,13.71,3.38c-4.76,0-9.44-1.23-14-2.62s-9-3-13.57-4Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls22}
      d="M703.41,286.54c.33,3,.52,6,.63,9s.14,6,0,9l-.28,4.48c-.11,1.49-.21,3-.27,4.48-.12,3-.11,6-.06,9s0,6-.05,9-.17,6-.37,9c-.43,6-1,11.92-2,17.83a.08.08,0,0,1-.16,0c0-6,.23-11.95.5-17.92.15-3,.37-6,.57-8.93s.42-5.95.68-8.93.42-6,.42-8.95-.18-6-.27-9,0-6,.07-8.95.21-6,.45-9a.1.1,0,0,1,.09-.07A.08.08,0,0,1,703.41,286.54Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls29}
      d="M701.8,351.81l45.25,21a83.59,83.59,0,0,1-5.9,18.5,1.67,1.67,0,0,1-.78,1,1.69,1.69,0,0,1-1.27-.16A168.75,168.75,0,0,1,700.31,370a5,5,0,0,1-2.49-6.16c.61-3.57,1.12-7.07,1.49-10.62A1.76,1.76,0,0,1,701.8,351.81Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls30}
      d="M704.77,353.19c-1.18,6.25-1.57,12.1-2.53,18.24a168,168,0,0,0,36.86,20.69,1.69,1.69,0,0,0,1.27.16,1.67,1.67,0,0,0,.78-1,83.59,83.59,0,0,0,5.9-18.5Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls31}
      d="M553.05,369.94a4.49,4.49,0,0,0,.37,1.93,4,4,0,0,0,1.88,1.55,43.7,43.7,0,0,0,6,2.25q17.33,5.54,34.74,10.82a2.14,2.14,0,0,0,2.44-.85,31.54,31.54,0,0,0,4.65-15.95,1.84,1.84,0,0,0-1.52-1.73q-15.84-3.63-31.67-7.36l-8.21-2-5.28-1.18A2,2,0,0,0,554,359C553.63,362.26,553,367.56,553.05,369.94Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls30}
      d="M601.61,368q-15.84-3.63-31.67-7.36l-2-.48c-.89,5.34-2.44,11-3.59,16.5q15.84,5,31.73,9.87a2.14,2.14,0,0,0,2.44-.85,31.54,31.54,0,0,0,4.65-15.95A1.84,1.84,0,0,0,601.61,368Z"
      transform="translate(-300.9 -99.06)"
    />
    <g className={styles.cls32}>
      {' '}
      <path
        className={styles.cls23}
        d="M640.63,271.89a5,5,0,0,0,.53,3.22,3.89,3.89,0,0,0,.92,1.12,5.07,5.07,0,0,0,1.22.71,3.53,3.53,0,0,0,3.12,0c1-.57,1.39-1.79,2.25-2.85a5.82,5.82,0,0,1,3.48-2.12,5.71,5.71,0,0,1,4.22.77,5.26,5.26,0,0,1,2.31,3.58,7,7,0,0,1-.58,3.87l-.19,0a12.74,12.74,0,0,0-.75-3.42,4.52,4.52,0,0,0-1.93-2.19,4.9,4.9,0,0,0-2.81-.71,6.53,6.53,0,0,0-3,1.05c-1,.59-1.56,1.79-2.64,2.59a5.19,5.19,0,0,1-3.9,1.11,4.13,4.13,0,0,1-3.15-2.85,3.94,3.94,0,0,1,.73-3.9Z"
        transform="translate(-300.9 -99.06)"
      />
    </g>
    <path
      className={styles.cls33}
      d="M585.82,391a43.43,43.43,0,0,0,9.51,2.9,4.13,4.13,0,0,0,2.67-.27,5.22,5.22,0,0,0,1.7-2.12c1-1.8,2-4.07,1-5.92a5.81,5.81,0,0,0-1.49-1.63,99.61,99.61,0,0,0-46.13-21.71,2,2,0,0,0-2.27,1.62c-.59,3.55-1.78,9-.78,11.3,1.26,2.89,8.32,4.47,10.93,5.6C568.88,384.14,578.47,386.37,585.82,391Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls34}
      d="M600.73,385.56a5.81,5.81,0,0,0-1.49-1.63,101.47,101.47,0,0,0-33-18.39,46.31,46.31,0,0,1-3,16.13c7.41,2.9,15.88,5.14,22.54,9.3a43.43,43.43,0,0,0,9.51,2.9,4.13,4.13,0,0,0,2.67-.27,5.22,5.22,0,0,0,1.7-2.12C600.7,389.68,601.7,387.41,600.73,385.56Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls35}
      d="M725,401.52a41.58,41.58,0,0,0,15.78-8.72l-30.45-27a160.93,160.93,0,0,0-12.67-10.49,3.81,3.81,0,0,0-2.29-1,3.56,3.56,0,0,0-2.13,1.12c-2.87,2.58-8.15,8.74-4.87,12.38,1.51,1.67,5.24,2.47,7.23,3.59a57.2,57.2,0,0,1,7,4.68,84.86,84.86,0,0,1,12.17,11.95C715.79,389.28,724.45,401.69,725,401.52Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls34}
      d="M710.35,365.79c-2.08-1.84-4.16-3.69-6.29-5.48-4.26,3.21-7.72,6.78-12.61,9.38a45.18,45.18,0,0,1,4.17,1.74,57.2,57.2,0,0,1,7,4.68,84.86,84.86,0,0,1,12.17,11.95c1,1.22,9.7,13.63,10.27,13.46a41.58,41.58,0,0,0,15.78-8.72Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls22}
      d="M756.87,318.23c0,1.15-.16,2.29-.26,3.44s-.21,2.28-.38,3.42a38.16,38.16,0,0,1-1.41,6.78c-.63,2.2-1.19,4.4-1.78,6.6l-1.85,6.61-1.85,6.59c-.64,2.19-1.25,4.39-1.81,6.59A108.8,108.8,0,0,0,745,371.68a.06.06,0,0,1-.06.05,0,0,0,0,1,0-.05c.56-9.16,3.06-18,5.46-26.84,1.2-4.41,2.29-8.85,3.65-13.22s2-8.9,2.8-13.4c0,0,0-.05.06,0S756.87,318.21,756.87,318.23Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls17}
      d="M636.14,415.12l-42.22,31.65a65.84,65.84,0,0,1-6.91,4.72,78.17,78.17,0,0,1-8.12,3.71,192.44,192.44,0,0,0-19.47,9.51c-3.1,1.74-6.33,3.76-7.8,7-1.62,3.56-.72,7.69.2,11.48l2.55,10.45c.54,2.21,1.14,4.54,2.75,6.14,2.13,2.12,5.42,2.42,8.42,2.45a94.6,94.6,0,0,0,13.14-.79c1.51-.2,3.13-.49,4.17-1.6a7,7,0,0,0,1.39-3.17l6.36-25.55a24.91,24.91,0,0,1,2.84-7.68,20.9,20.9,0,0,1,5.5-5.35c12.25-8.88,27.16-13.07,41.54-17.78a481.5,481.5,0,0,0,71-29.75c4.95-2.56,12.08-10.64,12.08-10.64s-4.37-5.79-6.14-8.27c-3.71-5.21-7.06-10.72-12.24-14.33A95.37,95.37,0,0,0,691,369.5s-7.14,7-9.62,9.25c-2.66,2.45-5.38,4.86-8.13,7.22-5.58,4.8-11.32,9.42-17.12,13.95C649.54,405.07,642.84,410.09,636.14,415.12Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls36}
      d="M572.8,502.05a41.57,41.57,0,0,0,5.88-.61c1.51-.2,3.13-.49,4.17-1.6a7,7,0,0,0,1.39-3.17l6.24-25.07C583,483.11,581.36,494.53,572.8,502.05Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls18}
      d="M717.39,391.65c-3.71-5.21-7.06-10.72-12.24-14.33A95.37,95.37,0,0,0,691,369.5h0c3.71,7.5,16.24,24.59,13.77,32-3,9-25.67,15.67-33.6,19.65-25.15,11.72-46.93,19.57-70.93,31.57-8.24,4.4-10.14,12.11-10.18,20.49l.52-2.11a24.91,24.91,0,0,1,2.84-7.68,20.9,20.9,0,0,1,5.5-5.35c12.25-8.88,27.16-13.07,41.54-17.78a481.5,481.5,0,0,0,71-29.75c4.95-2.56,12.08-10.64,12.08-10.64S719.16,394.13,717.39,391.65Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls17}
      d="M586,407.52c-1.52,2.44-4.15,3.94-6.66,5.36A570.08,570.08,0,0,0,519.23,452a19.78,19.78,0,0,0-7.36,10.86,6.64,6.64,0,0,0-.19,3.22c.38,1.56,1.66,2.72,2.72,3.93,3.46,4,5,9.9,2.71,14.64s-8.9,7.19-13.22,4.2a27,27,0,0,0-6.3-19.53c-7.52.4-13,7.51-15.31,14.69s-2.88,16.52-5.45,23.6a3,3,0,0,1-1,1.55,3.08,3.08,0,0,1-1.75.33c-7.73-.08-15.15-1.79-22.68-2.83a6.11,6.11,0,0,1-5.32-5.09q-.87-6-1.17-12a24.84,24.84,0,0,1,.84-9.26c2.46-7.16,10.08-10.91,16.85-14.29,19.92-9.95,38.53-23.19,52.32-40.67,12.53-15.88,21.44-35,35.8-49.26,0,0,12,5.25,13.67,5.85,5.08,1.89,10.52,3.85,15.52,5.94,4.46,1.87,8.47,4.06,8.47,4.06C588.17,396,588.21,404,586,407.52Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls37}
      d="M579.92,387.89c-5-2.09-10.44-4.05-15.52-5.94-.16-.05-.4-.15-.72-.28-5.62,15.36-5.65,34.3-28.56,48-33.81,20.19-59.56,48.46-62.41,52.9-5,7.73-4,18-2.69,26.74,5.8.95-1.9.16,4.07.22a3.08,3.08,0,0,0,1.75-.33,3,3,0,0,0,1-1.55c2.57-7.08,3.17-16.42,5.45-23.6s7.79-14.29,15.31-14.69a27,27,0,0,1,6.3,19.53c4.32,3,10.94.53,13.22-4.2S517.86,474,514.4,470c-1.06-1.21-2.34-2.37-2.72-3.93a6.64,6.64,0,0,1,.19-3.22A19.78,19.78,0,0,1,519.23,452a570.08,570.08,0,0,1,60.12-39.14c2.51-1.42,5.14-2.92,6.66-5.36,2.2-3.53,2.16-11.52,2.38-15.57C588.39,392,584.38,389.76,579.92,387.89Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls1}
      d="M339.54,658.24a385.19,385.19,0,0,1,52-1.63c14.79.54,30.75,2.44,41.58,12.53,1.72,1.61,3.36,4.2,2,6.15a5.5,5.5,0,0,1-2.59,1.7c-8.4,3.44-17,6.93-26.13,7.1-5.63.1-11.19-1.08-16.69-2.25l-47.18-10.09c-3.79-.81-17.84-1.45-18.74-6.46C322.73,659.46,335.7,658.62,339.54,658.24Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls38}
      d="M325.45,666.29c2.27-.5,4.41-1.39,6.61-2.09.7-.22,2.72-.42,4.24-.78a45.48,45.48,0,0,0,19,7.69c22.49,3.84,43.45-6.19,48.24-34.27,3.53-20.65-12.95-44.72-35.44-48.56-10.58-1.8-18.66-1.17-24.91,1.24a11.1,11.1,0,0,1-1.59-.36,22.76,22.76,0,0,1-4.86-2.49c-2.93-1.94-5.55-4.32-8.46-6.28A6.51,6.51,0,0,0,324.8,579a5.21,5.21,0,0,0-3.74,2,9.3,9.3,0,0,0-2.31,6.84,12.83,12.83,0,0,0,2.6,6.35,55.27,55.27,0,0,0,4.62,5.19,15.18,15.18,0,0,0,2.95,2.66c-3.05,5.25-5,11.53-6.44,18.26l-.46.37c-2.76,2.12-6.5,2.25-10,2.58s-7.29,1.18-9.24,4.07a10.11,10.11,0,0,0-1.25,2.9,15.5,15.5,0,0,0,.86,11.27,3.47,3.47,0,0,0,1.09,1.36,3.93,3.93,0,0,0,1.82.49,59,59,0,0,0,7.52.23,25.23,25.23,0,0,1,5.14.1,10.55,10.55,0,0,1,5.24,2.56,36.36,36.36,0,0,0,2.91,6.21,6,6,0,0,0-1.27,1,13.48,13.48,0,0,0-3.79,9.74,3.66,3.66,0,0,0,.93,2.67C322.9,666.65,324.28,666.55,325.45,666.29Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls39}
      d="M381.49,593.28c7.8,12.9,11,28.3.07,40.89-13,17-37.95,21-57.94,13a35.3,35.3,0,0,0,2.52,5.21,6,6,0,0,0-1.27,1,13.48,13.48,0,0,0-3.79,9.74,3.66,3.66,0,0,0,.93,2.67c.89.81,2.27.71,3.44.45,2.27-.5,4.41-1.39,6.61-2.09.7-.22,2.72-.42,4.24-.78a45.48,45.48,0,0,0,19,7.69c22.49,3.84,43.45-6.19,48.24-34.27C406.27,620.59,396.66,602.24,381.49,593.28Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls40}
      d="M384.76,641.29a16.58,16.58,0,0,1-1.54,10,10.35,10.35,0,0,1-8.1,5.56,17.22,17.22,0,0,1-3-.16l-5-.57a10.1,10.1,0,0,1-3.88-1c-1.76-1-2.73-2.94-3.6-4.76a2.07,2.07,0,0,1-.28-1.59c.14-.38,1.2-.81,1.58-1,3.9-1.4,7.55-1.22,11.1-3.17a16.12,16.12,0,0,0,3.16-2.74l3.37-3.17c.63-.6,2.46-3,3.28-3.09C383.41,635.46,385,640,384.76,641.29Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls41}
      d="M398.63,601.77a15.92,15.92,0,0,0,.71-7.42,7.17,7.17,0,0,0-4.5-5.59,9.83,9.83,0,0,0-4.73,0,43.36,43.36,0,0,0-10.36,3.39c1.37,1.55,3.32,2.21,4.69,3.76.8.91,1,2.72,1.48,3.85.66,1.66,1.51,2.83,1.72,4.61s.65,2.45.22,5c.69,1,3.49,4.31,4.86,4.17s2.76-3.89,3.32-5A42.29,42.29,0,0,0,398.63,601.77Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls42}
      d="M339.7,621c-10.14-2.48-9.75-11-7.11-19.16s11.35-12.91,17.16-11,8.09,9.58,5.45,17.77S345.64,622.42,339.7,621Z"
      transform="translate(-300.9 -99.06)"
    />
    <ellipse
      className={styles.cls43}
      cx="348.6"
      cy="598.16"
      rx="1.82"
      ry="3.54"
      transform="translate(-562.46 826.23) rotate(-87.96)"
    />
    <ellipse
      className={styles.cls43}
      cx="344.26"
      cy="612.08"
      rx="1.82"
      ry="3.54"
      transform="translate(-617.92 749.37) rotate(-80.31)"
    />
    <path
      className={styles.cls44}
      d="M387.62,608.66a.86.86,0,0,0,.24.7.75.75,0,0,0,.57,0c2.08-.4,3.42-2.4,4.4-4.29a51.93,51.93,0,0,0,3.15-7.5,8,8,0,0,0,.43-5,5.7,5.7,0,0,0-4.89-3.44,14.64,14.64,0,0,0-6.17,1.08c-1.85.64-4,1.16-5.79,1.9,0,.8,2.66,2.1,3.28,2.81a17.89,17.89,0,0,1,2.63,4.1A23.65,23.65,0,0,1,387.62,608.66Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls45}
      d="M381.53,645.64a9,9,0,0,1-6.73,5.87,12.38,12.38,0,0,1-4.33-.19,30.07,30.07,0,0,1-9.53-3.51,22.15,22.15,0,0,1,5.25-1.41,19.92,19.92,0,0,0,3.69-.64,12.85,12.85,0,0,0,4.11-2.68c1-.89,5.78-6.39,6.84-5.92.8.36,1.12,3.28,1.2,4A10.3,10.3,0,0,1,381.53,645.64Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls46}
      d="M397.63,628.76c-.58-.7-1.42-1.43-2.28-1.15s-1.07,1.21-1.16,2a4.36,4.36,0,0,0,.09,1.65,5.77,5.77,0,0,0,1.77,2.29,24.39,24.39,0,0,1,6.06,9.35,7.28,7.28,0,0,0,0-7.41A34.49,34.49,0,0,0,397.63,628.76Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls47}
      d="M374.1,600.46a2,2,0,0,0-1.48.34,1.37,1.37,0,0,0,.16,1.92,3.16,3.16,0,0,0,2,.67c.89.05,2.31-.21,2.23-1.39S375,600.5,374.1,600.46Z"
      transform="translate(-300.9 -99.06)"
    />
    <path
      className={styles.cls47}
      d="M365.19,626.86a2.57,2.57,0,0,0,.05,1.55c.56,1.55,4,4.43,4.32,1.34C369.76,627.85,365.83,624.65,365.19,626.86Z"
      transform="translate(-300.9 -99.06)"
    />
    <ellipse
      className={styles.cls48}
      cx="403.14"
      cy="651.84"
      rx="3.63"
      ry="15.38"
      transform="translate(-606.62 261.36) rotate(-35.92)"
    />
    <path
      className={styles.cls49}
      d="M391.75,641.1c-1.63,1.17,1.1,7.71,6.08,14.59s10.34,11.5,12,10.32l2.36-1.71c-1.62,1.18-7-3.45-12-10.33s-7.71-13.41-6.08-14.59Z"
      transform="translate(-300.9 -99.06)"
    />
    <polygon
      className={styles.cls4}
      points="110.24 565.41 108.27 566.77 110.18 565.33 110.24 565.41"
    />
    <polygon
      className={styles.cls4}
      points="108.89 564.84 106.8 566.29 108.83 564.75 108.89 564.84"
    />
    <polygon
      className={styles.cls4}
      points="107.39 563.9 105.38 565.29 107.33 563.82 107.39 563.9"
    />
    <polygon
      className={styles.cls4}
      points="105.88 562.53 105.81 562.55 105.82 562.54 103.68 564.03 105.77 562.47 105.77 562.46 105.78 562.46 105.85 562.44 105.88 562.53"
    />
    <polygon
      className={styles.cls4}
      points="104.26 560.92 101.85 562.61 104.21 560.84 104.26 560.92"
    />
    <polygon
      className={styles.cls4}
      points="102.69 559.19 100.28 560.88 102.63 559.11 102.69 559.19"
    />
    <polygon
      className={styles.cls4}
      points="101.03 557.23 98.64 558.9 100.97 557.14 101.03 557.23"
    />
    <polygon
      className={styles.cls4}
      points="99.29 554.94 96.9 556.61 99.23 554.86 99.29 554.94"
    />
    <polygon
      className={styles.cls4}
      points="97.76 552.74 97.6 552.79 97.61 552.78 95.49 554.26 97.56 552.71 97.56 552.7 97.57 552.7 97.73 552.64 97.76 552.74"
    />
    <polygon
      className={styles.cls4}
      points="96.59 550.89 94.39 552.42 96.53 550.81 96.59 550.89"
    />
    <polygon
      className={styles.cls4}
      points="95.58 549.13 93.3 550.72 95.52 549.05 95.58 549.13"
    />
    <polygon
      className={styles.cls4}
      points="94.55 547.12 94.42 547.18 94.43 547.18 92.27 548.68 94.37 547.1 94.38 547.1 94.38 547.1 94.51 547.03 94.55 547.12"
    />
    <polygon
      className={styles.cls4}
      points="93.64 545.2 91.34 546.8 93.58 545.12 93.64 545.2"
    />
    <polygon
      className={styles.cls4}
      points="93.03 543.47 92.98 543.48 93 543.47 90.99 544.87 92.94 543.39 92.95 543.38 92.97 543.38 93.02 543.37 93.03 543.47"
    />
    <polygon
      className={styles.cls4}
      points="92.72 541.86 90.94 543.09 92.66 541.78 92.72 541.86"
    />
  </svg>
);
