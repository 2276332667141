import type { ReactNode } from 'react';
import { createContext, useEffect, useState } from 'react';

import { Spinner } from 'common';
import { initialize as initializeI18n } from 'core/i18n';

export const I18nInit = createContext(false);

const I18nWrapper = ({ children }: { children: ReactNode }) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    Promise.all([initializeI18n()]).then(() => {
      setInitialized(true);
    });
  }, []);

  return (
    <I18nInit.Provider value={initialized}>
      {initialized ? children : <Spinner />}
    </I18nInit.Provider>
  );
};

export default I18nWrapper;
