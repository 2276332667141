import styled from 'styled-components';

import ctaImage from '../../../assets/images/cta-background-small.jpg';

const Figure = styled.figure`
  margin: 0;
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  max-height: 350px;
  object-fit: cover;
`;

const CTA = () => (
  <Figure>
    <Image src={ctaImage} alt="cta-image" />
  </Figure>
);

export default CTA;
