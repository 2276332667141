class FlexplanApi {
    constructor(fetchService) {
        this.fetchService = fetchService;
    }
    getFlexplan() {
        return this.fetchService.get('/flexplan');
    }
    cancelFlexplanChoices(policyId) {
        return this.fetchService.delete(`/flexplan/choices/${policyId}`);
    }
}
export default FlexplanApi;
