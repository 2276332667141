import UnknownUserModal from './modals/UnknownUserModal';
import Modal from './Modal';
import { useActiveModal, useModalActions } from './modal.store';

export const ModalContainer = () => {
  const activeModal = useActiveModal();
  const { closeModal } = useModalActions();

  return (
    <Modal isOpen={!!activeModal}>
      {activeModal?.type === 'unknownUser' && (
        <UnknownUserModal
          email={activeModal?.payload?.email}
          handleClose={closeModal}
          userEmail={activeModal?.payload?.userEmail}
        />
      )}
    </Modal>
  );
};
