import ReactModal from 'react-modal';

ReactModal.setAppElement('#root');

const customStyles: ReactModal.Styles = {
  overlay: {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    position: 'relative',
    width: '100%',
    margin: 'auto',
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '0px',
    maxWidth: '400px',
    minHeight: '100px',
    border: 'none',
  },
};

const Modal = ({ ...props }: ReactModal.Props) => {
  // Customize the following styles based on your modal implementation
  const mergedModalStyles: ReactModal.Styles = {
    ...customStyles,
    ...props.style,
  };

  return (
    <ReactModal isOpen={props.isOpen} style={mergedModalStyles}>
      {props.children}
    </ReactModal>
  );
};

export default Modal;
