import { useQuery } from 'react-query';

import type { ApiError, ApiUser } from '@pib/api';
import { userApi } from 'core/di';

import { userQueryKeys } from './cache';

export const useCurrentUser = () => {
  const { data, isLoading, error } = useQuery<ApiUser, ApiError>(
    userQueryKeys.getCurrentUser(),
    () => userApi.getCurrentUser(),
    {
      staleTime: Infinity,
    },
  );

  return {
    currentUser: data as ApiUser,
    isLoading,
    error,
  };
};
