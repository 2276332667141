class WizardApi {
    constructor(fetchService) {
        this.fetchService = fetchService;
    }
    getWizard({ policyId }) {
        return this.fetchService.get(`/wizard/${policyId}`);
    }
    sendMessage({ policyId, message }) {
        return this.fetchService.post(`/wizard/${policyId}/message`, {
            message,
        });
    }
    uploadDocument({ policyId, files }) {
        const formData = new FormData();
        files.forEach(file => {
            formData.append('files', file);
        });
        return this.fetchService.post(`/wizard/${policyId}/file`, formData);
    }
    completeWizardStep({ policyId, fields }) {
        return this.fetchService.post(`/wizard/${policyId}`, { fields });
    }
}
export default WizardApi;
