import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import clsx from 'clsx';

const LanguageSelect = () => {
  const { i18n } = useTranslation();
  const [language] = i18n.language.split('-');
  const queryClient = useQueryClient();

  const onChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    queryClient.invalidateQueries({ refetchActive: true });
    window.location.href = '/info';
  };

  return (
    <div className="flex flex-row items-center">
      <button
        type="button"
        className={clsx({ 'font-bold': language === 'en' })}
        onClick={() => onChangeLanguage('en')}
      >
        EN
      </button>
      <div className="px-2">-</div>
      <button
        type="button"
        className={clsx({ 'font-bold': language === 'nl' })}
        onClick={() => onChangeLanguage('nl')}
      >
        NL
      </button>
      <div className="px-2">-</div>
      <button
        type="button"
        className={clsx({ 'font-bold': language === 'fr' })}
        onClick={() => onChangeLanguage('fr')}
      >
        FR
      </button>
    </div>
  );
};

export default LanguageSelect;
