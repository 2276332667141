const months = [
  'januari',
  'februari',
  'maart',
  'april',
  'mei',
  'juni',
  'juli',
  'augustus',
  'september',
  'oktober',
  'november',
  'december',
];

export const formatDateMonthYear = (d: Date) => {
  return `${months[d.getMonth()]} ${d.getFullYear()}`;
};

const pad = (n: number) => (n < 10 ? `0${n}` : n);

export const formatDate = (d: string) => {
  const date = new Date(d);
  return `${pad(date.getDate())}/${pad(
    date.getMonth() + 1,
  )}/${date.getFullYear()}`;
};

export const formatTime = (d: string) => {
  const date = new Date(d);
  return `${pad(date.getHours())}:${pad(date.getMinutes())}`;
};
