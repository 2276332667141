import type { ReactNode } from 'react';
import { Component } from 'react';
import styled, { keyframes } from 'styled-components';

import { Icon } from 'modules/ui';

import withUnmountDelay from '../withUnmountDelay';

const fadeInDown = keyframes`
  from {
    transform: translate3d(0, -100%, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;

const fadeOutUp = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
`;

interface Variant {
  visible: boolean;
  borderColor: string;
  bgColor: string;
}

const Alert = styled.div<{ variant: Variant }>`
  animation-name: ${p => (p.variant.visible ? fadeInDown : fadeOutUp)};
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  border: 1px solid ${p => p.variant.borderColor};
  background-color: ${p => p.variant.bgColor};
  padding: 15px;
  position: relative;
`;

export enum AlertType {
  Success = 'success',
  Error = 'error',
}

interface Props {
  message: ReactNode;
  type: AlertType;
  children?: ReactNode;
  visible: boolean;
  canClose?: boolean;
  triggerInvisible?: () => void;
}

interface State {
  visible: boolean;
}

interface Options {
  title?: string;
  icon: {
    type: string;
    color: string;
  };
  variant: Variant;
}

class AlertCmp extends Component<Props, State> {
  static defaultProps = {
    canClose: true,
    visible: false,
  };

  state = {
    visible: this.props.visible,
  };

  handleClose = () => {
    const { triggerInvisible, canClose } = this.props;
    if (canClose) {
      this.setState({ visible: false });
      if (triggerInvisible) {
        setTimeout(() => {
          triggerInvisible();
        }, 300);
      }
    }
  };

  render() {
    const { visible } = this.state;
    const { message, type, children, canClose } = this.props;
    const options = {} as Options;
    if (type === AlertType.Error) {
      options.icon = {
        type: 'times-circle',
        color: '#f5222d',
      };
      options.variant = {
        borderColor: '#ffa39e',
        bgColor: '#fff1f0',
        visible,
      };
      options.title = 'Er ging iets mis';
    }
    if (type === AlertType.Success) {
      options.icon = {
        type: 'check-circle',
        color: '#52c41a',
      };
      options.variant = {
        borderColor: '#b7eb8f',
        bgColor: '#f6ffed',
        visible,
      };
    }
    return (
      <Alert variant={options.variant}>
        <Icon type={options.icon.type} color={options.icon.color} />
        <div className="flex flex-col ml-3">
          {options.title && (
            <span className="font-bold mb-3">{options.title}</span>
          )}
          <span>{message}</span>
          {children}
        </div>
        {canClose && (
          <Icon
            className="ml-auto -mt-1"
            type="times"
            fontSize={2}
            onClick={this.handleClose}
          />
        )}
      </Alert>
    );
  }
}

export default withUnmountDelay(AlertCmp);
