class BlobApi {
    constructor(fetchService) {
        this.fetchService = fetchService;
    }
    getBlob(uri) {
        return this.fetchService.get(`/blob?uri=${uri}`, {
            resultAsBlob: true,
        });
    }
}
export default BlobApi;
