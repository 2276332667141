import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';

export const initialize = () =>
  i18n
    .use(LanguageDetector)
    .use(
      resourcesToBackend((language, _, callback) => {
        // eslint-disable-next-line import/dynamic-import-chunkname
        import(
          /* webpackChunkName: "locales/[request]" */ `./locales/${language}.json`
        )
          .then(resources => {
            callback(null, resources.default);
          })
          .catch(error => {
            callback(error, null);
          });
      }),
    )
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      debug: false,
      react: {
        useSuspense: false, // we explicitly don't want to use suspense
      },
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    });
