import * as S from '../components/styles';
import illustration from './illustration';

const Illustration = S.getIllustration(illustration);
export default () => {
  return (
    <S.Content>
      <S.Title>404</S.Title>
      <S.SubTitle>Not found</S.SubTitle>
      <S.Link to="/">Home</S.Link>
      <Illustration />
    </S.Content>
  );
};
