import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import type { ApiUser } from '@pib/api';

import UserQueryKeys from './UserQueryKeys';

export const userQueryKeys = new UserQueryKeys('user');

export const useSetCurrentUserCache = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (user: ApiUser) =>
      queryClient.setQueryData(userQueryKeys.getCurrentUser(), user),
    [queryClient],
  );
};
